.component-placeholder {
  animation: 1s linear forwards infinite placeHolderAnimation;
  background: #f6f7f8;
  background: linear-gradient(to right, rgb(0 0 0 / .1) 8%, rgb(230 230 230 / .3) 18%, rgb(0 0 0 / .1) 33%); // stylelint-disable-line  plugin/no-unsupported-browser-features
  background-size: 200% 100%;
  position: relative;
}

@keyframes placeHolderAnimation {
  0% {
    background-position: 100% 0;
  }

  100% {
    background-position: -100% 0;
  }
}

@mixin componentPreloader() {
  @extend .component-placeholder;

  @content;
}
