@mixin container($containerWidth: $maxWidthOuter) {
  width: 100%;
  max-width: $containerWidth;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $screen-mobile-large) {
    padding-left: 15px;
    padding-right: 15px;
  }

  @media (min-width: $screen-tablet-portrait) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
