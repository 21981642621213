@use "sass:math";

$columnCount: 12;

@mixin grid-col($i, $min-width) {
  @media (min-width: $min-width) {
    width: calc(100% / #{math.div($columnCount, $i)});
  }

  &.nopadding {
    padding-left: 0;
    padding-right: 0;
  }
}

.grid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  &--gutter-25 {
    $gutter: 25px;
    padding-right: $gutter;
    padding-left: $gutter;

    > .row {
      margin-left: -$gutter !important;
      margin-right: -$gutter !important;

      & > [class*="col-"] {
        padding: 0 $gutter $gutter;
      }
    }
  }

  .row {
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    &:empty {
      display: none;
    }

    &--no-margin {
      margin: 0;
    }

    &--background-image {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      padding-top: 40px;
    }
  }

  .col-auto {
    width: auto;
    padding: 0 5px 5px;
  }

  .col {
    @include grid-col(12, 0);
  }

  @for $i from 1 through $columnCount {
    .col-xs-#{$i} {
      @include grid-col($i, 0);
    }
  }

  @for $i from 1 through $columnCount {
    .col-sm-#{$i} {
      @include grid-col($i, $screen-mobile-landscape);
    }
  }

  @for $i from 1 through $columnCount {
    .col-md-#{$i} {
      @include grid-col($i, $screen-tablet-portrait);
    }
  }

  @for $i from 1 through $columnCount {
    .col-lg-#{$i} {
      @include grid-col($i, $screen-tablet-landscape);
    }
  }

  @for $i from 1 through $columnCount {
    .col-xl-#{$i} {
      @include grid-col($i, $screen-desktop);
    }
  }

  .col-lg-20percent {
    @media (min-width: $screen-tablet-landscape) {
      width: 20%;
    }
  }

  &.teaser-in-column {
    padding-left: 0;
    padding-right: 0;
  }

  .page-main .user-area__teaser &,
  .page-main .cart-page &,
  .page-main .checkout-step-confirmation &,
  .page-main .category-page &,
  .page-main .landing-page &,
  .page-main .refer-a-friend-page &,
  .page-main .product-detailed-page__bottom &,
  .page-main .product-detailed-page__section--teasers &,
  .page-main .error-page--grid &,
  .page-home .page-main & {
    & > .row > * > *:not(.row--full-width) {
      @include container();
    }
  }

  .hidden {
    &-xs {
      display: none;
    }

    &-sm {
      @media (min-width: $screen-tablet-portrait) {
        display: none;
      }
    }

    &-lg {
      @media (min-width: $screen-tablet-landscape) {
        display: none;
      }
    }
  }

  .visible {
    &-xs {
      display: block;
    }

    &-sm {
      @media (min-width: $screen-tablet-portrait) {
        display: block;
      }
    }

    &-lg {
      @media (min-width: $screen-tablet-landscape) {
        display: block;
      }
    }
  }
}
