@font-face {
  font-family: iconfont;
  src: url("../fonts/iconfont.eot");
  src:
    url("../fonts/iconfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/iconfont.woff") format("woff"),
    url("../fonts/iconfont.ttf") format("truetype"),
    url("../fonts/iconfont.svg#iconfont") format("svg");
  font-style: normal;
  font-weight: 400;
}
