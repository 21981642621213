@mixin iconFont($icon, $pseudoElementType: before) {
  &::before,
  &::after {
    font-family: iconfont, sans-serif;
    display: inline-block;
    text-decoration: inherit;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &::#{$pseudoElementType} {
    content: map-get($icons, $icon);

    @content;
  }
}
