$icons: (
  aboAuswahl: "\ea02",
  aboAward: "\ea03",
  aboBook: "\ea04",
  aboComfortable: "\ea05",
  aboDelivery: "\ea06",
  aboDiscovered: "\ea07",
  aboEuro: "\ea08",
  aboExclusiv: "\ea09",
  aboFlexibel: "\ea0a",
  aboGeniessen: "\ea0b",
  aboGeschenk: "\ea0c",
  aboLine: "\ea0d",
  aboNoRisk: "\ea0e",
  aboQuality: "\ea0f",
  aboRecommended: "\ea10",
  aboSavings: "\ea11",
  aboThanks: "\ea12",
  aboWahl: "\ea13",
  arrowDown: "\ea14",
  arrowDownBold: "\ea15",
  arrowLeft: "\ea16",
  arrowLeftBold: "\ea17",
  arrowLineRight: "\ea18",
  arrowLineUp: "\ea19",
  arrowRight: "\ea1a",
  arrowRightBold: "\ea1b",
  arrowRightThin: "\ea1c",
  arrowUp: "\ea1d",
  arrowUpBold: "\ea1e",
  benefitsBottle: "\ea1f",
  benefitsChecklist: "\ea20",
  benefitsDhl: "\ea21",
  benefitsGaranty: "\ea22",
  benefitsPaket: "\ea23",
  benefitsService: "\ea24",
  benefitsThumb: "\ea25",
  benefitsWineglas: "\ea26",
  calendar: "\ea27",
  camera: "\ea28",
  cart: "\ea29",
  category: "\ea2a",
  certificate: "\ea2b",
  check: "\ea2c",
  checkSmall: "\ea2d",
  checked: "\ea2e",
  checklist: "\ea2f",
  clock: "\ea30",
  close: "\ea31",
  cloud: "\ea32",
  comment: "\ea33",
  confirmationArrow: "\ea34",
  confirmationBox: "\ea35",
  confirmationHome: "\ea36",
  confirmationMail: "\ea37",
  confirmationTracking: "\ea38",
  confirmationVersand: "\ea39",
  countdown: "\ea3a",
  delete: "\ea3b",
  delivery: "\ea3c",
  documentCheck: "\ea3d",
  download: "\ea3e",
  edit: "\ea3f",
  email: "\ea40",
  enzoLogo: "\ea41",
  eurCircle: "\ea42",
  exitFullscreenIcon: "\ea43",
  expand: "\ea44",
  eye: "\ea45",
  facebook: "\ea46",
  facebookInverted: "\ea47",
  faviconEnzo: "\ea48",
  filter: "\ea49",
  freeShippingIcon: "\ea4a",
  fwAuswaehlen: "\ea4b",
  fwEinkaufen: "\ea4c",
  fwEmpfehlen: "\ea4d",
  fwPraemieErhalten: "\ea4e",
  giftcard: "\ea4f",
  grape: "\ea50",
  grapes: "\ea51",
  haweskoPartner: "\ea52",
  headerItalienflagge: "\ea53",
  headset: "\ea54",
  heart: "\ea55",
  heartFilled: "\ea56",
  heartUnfilled: "\ea57",
  home: "\ea58",
  info: "\ea59",
  infoPopup: "\ea5a",
  instagram: "\ea5b",
  letter: "\ea5c",
  linkLetter: "\ea5d",
  loading: "\ea5e",
  logoClaim: "\ea5f",
  mail: "\ea60",
  mailOpen: "\ea61",
  medalIcon: "\ea62",
  mobileMenu: "\ea63",
  packet: "\ea64",
  phone: "\ea65",
  plus: "\ea66",
  present: "\ea67",
  productAnbaugebiet: "\ea68",
  productBox: "\ea69",
  productLagerfaehigkeit: "\ea6a",
  productRebsorten: "\ea6b",
  productTrinktemperatur: "\ea6c",
  productReturn: "\ea6d",
  quality: "\ea6e",
  redwine: "\ea6f",
  regions: "\ea70",
  reload: "\ea71",
  return: "\ea72",
  rosewine: "\ea73",
  search: "\ea74",
  searchNativeMenu: "\ea75",
  service: "\ea76",
  shipmentIcon: "\ea77",
  shipping: "\ea78",
  similarSearch: "\ea79",
  star: "\ea7a",
  statusDelayed: "\ea7b",
  statusDelivery: "\ea7c",
  statusHome: "\ea7d",
  statusPackage: "\ea7e",
  statusReceived: "\ea7f",
  talk: "\ea80",
  taste: "\ea81",
  thumbDown: "\ea82",
  thumbUp: "\ea83",
  thumbUpFill: "\ea84",
  time: "\ea85",
  trash: "\ea86",
  truck: "\ea87",
  twitter: "\ea88",
  user: "\ea89",
  userMailCheck: "\ea8a",
  varities: "\ea8b",
  vcard: "\ea8c",
  video: "\ea8d",
  voucherFive: "\ea8e",
  wallet: "\ea8f",
  whatsapp: "\ea90",
  winemaker: "\ea91",
  worldMap: "\ea92",
  zoomIn: "\ea93",

);