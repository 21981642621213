// Fonts

$fontSizes: (
  xxs: $fontSizeXXSmall,
  xs: $fontSizeXSmall,
  s: $fontSizeSmall,
  base: $baseFontSize,
  f: $fontSizeFont,
  m: $fontSizeMedium,
  xm: $fontSizeXMedium,
  xxm: $fontSizeXXMedium,
  l: $fontSizeLarge,
  xl: $fontSizeXLarge,
  xxl: $fontSizeXXLarge,
  xxxl: $fontSizeXXXLarge,
  huge: $fontSizeHuge
);

@mixin fontSize($fontSize: $baseFontSize, $fixedValue: false) {
  $mSize: ceil($fontSize * .87);
  $tSize: ceil($fontSize * .9);
  //
  @if $fixedValue {
    $mSize: get-fixed-value($mSize, $fontSizes);
    $tSize: get-fixed-value($tSize, $fontSizes);
    $fontSize: get-fixed-value($fontSize, $fontSizes);
  }
  font-size: $mSize;

  @if $tSize != $mSize {
    @media (min-width: $screen-tablet-portrait) {
      font-size: $tSize;
    }
  }

  @if $fontSize != $tSize {
    @media (min-width: $screen-laptop) {
      font-size: $fontSize;
    }
  }
}

@function get-fixed-value($value, $valuesList) {
  $bestMatchIndex: 0;
  $bestMatchValue: 100;

  @each $index, $listItem in $valuesList {
    $diff: abs($value - $listItem);

    @if $diff == 0 {
      @return map-get($valuesList, $index);
    }

    @if $diff < $bestMatchValue {
      $bestMatchValue: $diff;
      $bestMatchIndex: $index;
    }
  }

  @return map-get($valuesList, $bestMatchIndex);
}
