@font-face {
  font-family: Montserrat;
  src:
    local('Montserrat-Medium'),
    url('../fonts/Montserrat-Medium.ttf') format('truetype');
  font-weight: 300 400;
  font-style: normal;
  font-display: swap; // stylelint-disable-line plugin/no-unsupported-browser-features
}

@font-face {
  font-family: Montserrat;
  src:
    local('Montserrat-Bold'),
    url('../fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap; // stylelint-disable-line plugin/no-unsupported-browser-features
}

@import 'https://fonts.googleapis.com/css2?family=Montserrat:wght@300..700&display=swap'; // stylelint-disable-line
