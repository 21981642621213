.icon {
  @include iconFont('', before) {
    font-size: 40px;
    color: $textBaseColor;

    @media (min-width: $screen-desktop-sm) {
      font-size: 42px;
    }
  }
  position: relative;
  text-decoration: none;
  text-align: center;

  &:hover,
  &:visited {
    text-decoration: none;
  }

  @each $name, $glyph in $icons {
    &--#{$name}::before {
      content: $glyph;
    }
  }

  &.icon--filled {
    padding: 0;
    display: flex;
    font-size: 17px;
    line-height: 1.4;
    font-weight: bold;
    height: 76px;
    justify-content: center;
    flex-flow: column;
    width: 100%;

    &::after {
      width: 65px;
      height: 65px;
      position: absolute;
      content: "";
      margin: auto;
      right: 0;
      left: 0;
      z-index: 1;
      border-radius: 100%;
      background: $brandPrimaryColor;
    }

    &::before {
      color: $whiteColor;
      z-index: 10;
    }
  }

  &--background-success {
    background: $successColor;
    border-radius: 100%;
    width: 28px;
    height: 28px;
    display: inline-block;

    &::before {
      color: $whiteColor;
      top: -2px;
      font-size: 13px;
      position: relative;
    }
  }

  &--haweskoPartner {
    line-height: 1.2;

    &::before {
      font-size: 12px;

      @media (min-width: $screen-tablet-portrait) {
        font-size: 14px;
      }
    }
  }
}
