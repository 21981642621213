.grid {
  --grid-element-background-individual: transparent;

  &__element {
    &--background-individual {
      background: var(--grid-element-background-individual);
    }

    &--bg-color1 {
      background: $gridBackgroundColor1;
    }

    &--bg-color2 {
      background: $gridBackgroundColor2;
    }

    &--bg-color3 {
      background: $gridBackgroundColor3;
    }

    &--bg-color4 {
      background: $gridBackgroundColor4;
    }

    &--bg-color5 {
      background: $gridBackgroundColor5;
    }

    &--groupedContent1 {
      padding: 20px;
      margin: 32px 0 0;

      @media (min-width: $screen-laptop) {
        padding: 40px;
        margin: 40px 0 0;
      }
    }

    &--groupedContent2 {
      padding: 0 20px;

      @media (min-width: $screen-laptop) {
        padding: 0 40px;
      }
    }

    &--groupedContent3 {
      padding: 0 20px 20px;

      @media (min-width: $screen-laptop) {
        padding: 0 40px 40px;
      }
    }

    &--teaser {
      --grid-element-teaser-background: $gridElementTeaserBackground;
      --grid-element-teaser-text-color: $gridElementTeaserBackground;
    }
  }
}

.default-layout {
  .grid {
    &--raster {
      > .row > div:not(:empty) {
        padding-top: 30px;
        padding-bottom: 30px;

        @media (min-width: $screen-tablet-portrait) {
          padding-top: 40px;
          padding-bottom: 40px;
        }
      }

      > .row:first-child,
      .row.row--default + .row.row--default,
      .row.grid__element--bg-color1 + .row.grid__element--bg-color1,
      .row.grid__element--bg-color2 + .row.grid__element--bg-color2,
      .row.grid__element--bg-color3 + .row.grid__element--bg-color3,
      .row.grid__element--bg-color4 + .row.grid__element--bg-color4,
      .row.grid__element--bg-color5 + .row.grid__element--bg-color5 {
        > div {
          padding-top: 0;

          @media (min-width: $screen-tablet-portrait) {
            padding-top: 0;
          }
        }
      }

      .row.row--default:last-child > div {
        padding-bottom: 0;

        @media (min-width: $screen-tablet-portrait) {
          padding-bottom: 0;
        }
      }
    }
  }
}
