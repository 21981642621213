// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$sprite-amex-name: 'sprite-amex';
$sprite-amex-x: 0px;
$sprite-amex-y: 147px;
$sprite-amex-offset-x: 0px;
$sprite-amex-offset-y: -147px;
$sprite-amex-width: 45px;
$sprite-amex-height: 33px;
$sprite-amex-total-width: 205px;
$sprite-amex-total-height: 1368px;
$sprite-amex-image: '../staticImages/img-sprite.png';
$sprite-amex: (0px, 147px, 0px, -147px, 45px, 33px, 205px, 1368px, '../staticImages/img-sprite.png', 'sprite-amex', );
$sprite-apple-pay-name: 'sprite-apple-pay';
$sprite-apple-pay-x: 0px;
$sprite-apple-pay-y: 760px;
$sprite-apple-pay-offset-x: 0px;
$sprite-apple-pay-offset-y: -760px;
$sprite-apple-pay-width: 51px;
$sprite-apple-pay-height: 36px;
$sprite-apple-pay-total-width: 205px;
$sprite-apple-pay-total-height: 1368px;
$sprite-apple-pay-image: '../staticImages/img-sprite.png';
$sprite-apple-pay: (0px, 760px, 0px, -760px, 51px, 36px, 205px, 1368px, '../staticImages/img-sprite.png', 'sprite-apple-pay', );
$sprite-appstore-name: 'sprite-appstore';
$sprite-appstore-x: 0px;
$sprite-appstore-y: 378px;
$sprite-appstore-offset-x: 0px;
$sprite-appstore-offset-y: -378px;
$sprite-appstore-width: 102px;
$sprite-appstore-height: 34px;
$sprite-appstore-total-width: 205px;
$sprite-appstore-total-height: 1368px;
$sprite-appstore-image: '../staticImages/img-sprite.png';
$sprite-appstore: (0px, 378px, 0px, -378px, 102px, 34px, 205px, 1368px, '../staticImages/img-sprite.png', 'sprite-appstore', );
$sprite-bester-online-wein-name: 'sprite-besterOnlineWein';
$sprite-bester-online-wein-x: 0px;
$sprite-bester-online-wein-y: 1264px;
$sprite-bester-online-wein-offset-x: 0px;
$sprite-bester-online-wein-offset-y: -1264px;
$sprite-bester-online-wein-width: 100px;
$sprite-bester-online-wein-height: 104px;
$sprite-bester-online-wein-total-width: 205px;
$sprite-bester-online-wein-total-height: 1368px;
$sprite-bester-online-wein-image: '../staticImages/img-sprite.png';
$sprite-bester-online-wein: (0px, 1264px, 0px, -1264px, 100px, 104px, 205px, 1368px, '../staticImages/img-sprite.png', 'sprite-besterOnlineWein', );
$sprite-creditcard-name: 'sprite-creditcard';
$sprite-creditcard-x: 0px;
$sprite-creditcard-y: 180px;
$sprite-creditcard-offset-x: 0px;
$sprite-creditcard-offset-y: -180px;
$sprite-creditcard-width: 45px;
$sprite-creditcard-height: 33px;
$sprite-creditcard-total-width: 205px;
$sprite-creditcard-total-height: 1368px;
$sprite-creditcard-image: '../staticImages/img-sprite.png';
$sprite-creditcard: (0px, 180px, 0px, -180px, 45px, 33px, 205px, 1368px, '../staticImages/img-sprite.png', 'sprite-creditcard', );
$sprite-creditcards-wide-name: 'sprite-creditcards-wide';
$sprite-creditcards-wide-x: 0px;
$sprite-creditcards-wide-y: 19px;
$sprite-creditcards-wide-offset-x: 0px;
$sprite-creditcards-wide-offset-y: -19px;
$sprite-creditcards-wide-width: 110px;
$sprite-creditcards-wide-height: 22px;
$sprite-creditcards-wide-total-width: 205px;
$sprite-creditcards-wide-total-height: 1368px;
$sprite-creditcards-wide-image: '../staticImages/img-sprite.png';
$sprite-creditcards-wide: (0px, 19px, 0px, -19px, 110px, 22px, 205px, 1368px, '../staticImages/img-sprite.png', 'sprite-creditcards-wide', );
$sprite-dhl-name: 'sprite-dhl';
$sprite-dhl-x: 0px;
$sprite-dhl-y: 66px;
$sprite-dhl-offset-x: 0px;
$sprite-dhl-offset-y: -66px;
$sprite-dhl-width: 122px;
$sprite-dhl-height: 27px;
$sprite-dhl-total-width: 205px;
$sprite-dhl-total-height: 1368px;
$sprite-dhl-image: '../staticImages/img-sprite.png';
$sprite-dhl: (0px, 66px, 0px, -66px, 122px, 27px, 205px, 1368px, '../staticImages/img-sprite.png', 'sprite-dhl', );
$sprite-elbphilharmonie-name: 'sprite-elbphilharmonie';
$sprite-elbphilharmonie-x: 0px;
$sprite-elbphilharmonie-y: 941px;
$sprite-elbphilharmonie-offset-x: 0px;
$sprite-elbphilharmonie-offset-y: -941px;
$sprite-elbphilharmonie-width: 118px;
$sprite-elbphilharmonie-height: 38px;
$sprite-elbphilharmonie-total-width: 205px;
$sprite-elbphilharmonie-total-height: 1368px;
$sprite-elbphilharmonie-image: '../staticImages/img-sprite.png';
$sprite-elbphilharmonie: (0px, 941px, 0px, -941px, 118px, 38px, 205px, 1368px, '../staticImages/img-sprite.png', 'sprite-elbphilharmonie', );
$sprite-facebook-name: 'sprite-facebook';
$sprite-facebook-x: 0px;
$sprite-facebook-y: 796px;
$sprite-facebook-offset-x: 0px;
$sprite-facebook-offset-y: -796px;
$sprite-facebook-width: 36px;
$sprite-facebook-height: 36px;
$sprite-facebook-total-width: 205px;
$sprite-facebook-total-height: 1368px;
$sprite-facebook-image: '../staticImages/img-sprite.png';
$sprite-facebook: (0px, 796px, 0px, -796px, 36px, 36px, 205px, 1368px, '../staticImages/img-sprite.png', 'sprite-facebook', );
$sprite-forecast-dhl-name: 'sprite-forecast-dhl';
$sprite-forecast-dhl-x: 0px;
$sprite-forecast-dhl-y: 480px;
$sprite-forecast-dhl-offset-x: 0px;
$sprite-forecast-dhl-offset-y: -480px;
$sprite-forecast-dhl-width: 51px;
$sprite-forecast-dhl-height: 35px;
$sprite-forecast-dhl-total-width: 205px;
$sprite-forecast-dhl-total-height: 1368px;
$sprite-forecast-dhl-image: '../staticImages/img-sprite.png';
$sprite-forecast-dhl: (0px, 480px, 0px, -480px, 51px, 35px, 205px, 1368px, '../staticImages/img-sprite.png', 'sprite-forecast-dhl', );
$sprite-forecast-dhl-eu-name: 'sprite-forecast-dhl_eu';
$sprite-forecast-dhl-eu-x: 0px;
$sprite-forecast-dhl-eu-y: 515px;
$sprite-forecast-dhl-eu-offset-x: 0px;
$sprite-forecast-dhl-eu-offset-y: -515px;
$sprite-forecast-dhl-eu-width: 51px;
$sprite-forecast-dhl-eu-height: 35px;
$sprite-forecast-dhl-eu-total-width: 205px;
$sprite-forecast-dhl-eu-total-height: 1368px;
$sprite-forecast-dhl-eu-image: '../staticImages/img-sprite.png';
$sprite-forecast-dhl-eu: (0px, 515px, 0px, -515px, 51px, 35px, 205px, 1368px, '../staticImages/img-sprite.png', 'sprite-forecast-dhl_eu', );
$sprite-forecast-dhl-express-name: 'sprite-forecast-dhl_express';
$sprite-forecast-dhl-express-x: 0px;
$sprite-forecast-dhl-express-y: 550px;
$sprite-forecast-dhl-express-offset-x: 0px;
$sprite-forecast-dhl-express-offset-y: -550px;
$sprite-forecast-dhl-express-width: 51px;
$sprite-forecast-dhl-express-height: 35px;
$sprite-forecast-dhl-express-total-width: 205px;
$sprite-forecast-dhl-express-total-height: 1368px;
$sprite-forecast-dhl-express-image: '../staticImages/img-sprite.png';
$sprite-forecast-dhl-express: (0px, 550px, 0px, -550px, 51px, 35px, 205px, 1368px, '../staticImages/img-sprite.png', 'sprite-forecast-dhl_express', );
$sprite-forecast-hermes-name: 'sprite-forecast-hermes';
$sprite-forecast-hermes-x: 0px;
$sprite-forecast-hermes-y: 585px;
$sprite-forecast-hermes-offset-x: 0px;
$sprite-forecast-hermes-offset-y: -585px;
$sprite-forecast-hermes-width: 51px;
$sprite-forecast-hermes-height: 35px;
$sprite-forecast-hermes-total-width: 205px;
$sprite-forecast-hermes-total-height: 1368px;
$sprite-forecast-hermes-image: '../staticImages/img-sprite.png';
$sprite-forecast-hermes: (0px, 585px, 0px, -585px, 51px, 35px, 205px, 1368px, '../staticImages/img-sprite.png', 'sprite-forecast-hermes', );
$sprite-forecast-unknown-name: 'sprite-forecast-unknown';
$sprite-forecast-unknown-x: 0px;
$sprite-forecast-unknown-y: 620px;
$sprite-forecast-unknown-offset-x: 0px;
$sprite-forecast-unknown-offset-y: -620px;
$sprite-forecast-unknown-width: 51px;
$sprite-forecast-unknown-height: 35px;
$sprite-forecast-unknown-total-width: 205px;
$sprite-forecast-unknown-total-height: 1368px;
$sprite-forecast-unknown-image: '../staticImages/img-sprite.png';
$sprite-forecast-unknown: (0px, 620px, 0px, -620px, 51px, 35px, 205px, 1368px, '../staticImages/img-sprite.png', 'sprite-forecast-unknown', );
$sprite-giftvoucher-name: 'sprite-giftvoucher';
$sprite-giftvoucher-x: 0px;
$sprite-giftvoucher-y: 832px;
$sprite-giftvoucher-offset-x: 0px;
$sprite-giftvoucher-offset-y: -832px;
$sprite-giftvoucher-width: 51px;
$sprite-giftvoucher-height: 36px;
$sprite-giftvoucher-total-width: 205px;
$sprite-giftvoucher-total-height: 1368px;
$sprite-giftvoucher-image: '../staticImages/img-sprite.png';
$sprite-giftvoucher: (0px, 832px, 0px, -832px, 51px, 36px, 205px, 1368px, '../staticImages/img-sprite.png', 'sprite-giftvoucher', );
$sprite-googleplay-name: 'sprite-googleplay';
$sprite-googleplay-x: 0px;
$sprite-googleplay-y: 412px;
$sprite-googleplay-offset-x: 0px;
$sprite-googleplay-offset-y: -412px;
$sprite-googleplay-width: 115px;
$sprite-googleplay-height: 34px;
$sprite-googleplay-total-width: 205px;
$sprite-googleplay-total-height: 1368px;
$sprite-googleplay-image: '../staticImages/img-sprite.png';
$sprite-googleplay: (0px, 412px, 0px, -412px, 115px, 34px, 205px, 1368px, '../staticImages/img-sprite.png', 'sprite-googleplay', );
$sprite-heart-finger-name: 'sprite-heart-finger';
$sprite-heart-finger-x: 0px;
$sprite-heart-finger-y: 1178px;
$sprite-heart-finger-offset-x: 0px;
$sprite-heart-finger-offset-y: -1178px;
$sprite-heart-finger-width: 58px;
$sprite-heart-finger-height: 86px;
$sprite-heart-finger-total-width: 205px;
$sprite-heart-finger-total-height: 1368px;
$sprite-heart-finger-image: '../staticImages/img-sprite.png';
$sprite-heart-finger: (0px, 1178px, 0px, -1178px, 58px, 86px, 205px, 1368px, '../staticImages/img-sprite.png', 'sprite-heart-finger', );
$sprite-hermes-name: 'sprite-hermes';
$sprite-hermes-x: 0px;
$sprite-hermes-y: 93px;
$sprite-hermes-offset-x: 0px;
$sprite-hermes-offset-y: -93px;
$sprite-hermes-width: 122px;
$sprite-hermes-height: 27px;
$sprite-hermes-total-width: 205px;
$sprite-hermes-total-height: 1368px;
$sprite-hermes-image: '../staticImages/img-sprite.png';
$sprite-hermes: (0px, 93px, 0px, -93px, 122px, 27px, 205px, 1368px, '../staticImages/img-sprite.png', 'sprite-hermes', );
$sprite-hermeslogo-name: 'sprite-hermeslogo';
$sprite-hermeslogo-x: 0px;
$sprite-hermeslogo-y: 655px;
$sprite-hermeslogo-offset-x: 0px;
$sprite-hermeslogo-offset-y: -655px;
$sprite-hermeslogo-width: 80px;
$sprite-hermeslogo-height: 35px;
$sprite-hermeslogo-total-width: 205px;
$sprite-hermeslogo-total-height: 1368px;
$sprite-hermeslogo-image: '../staticImages/img-sprite.png';
$sprite-hermeslogo: (0px, 655px, 0px, -655px, 80px, 35px, 205px, 1368px, '../staticImages/img-sprite.png', 'sprite-hermeslogo', );
$sprite-hermespaketshop-name: 'sprite-hermespaketshop';
$sprite-hermespaketshop-x: 0px;
$sprite-hermespaketshop-y: 1072px;
$sprite-hermespaketshop-offset-x: 0px;
$sprite-hermespaketshop-offset-y: -1072px;
$sprite-hermespaketshop-width: 62px;
$sprite-hermespaketshop-height: 51px;
$sprite-hermespaketshop-total-width: 205px;
$sprite-hermespaketshop-total-height: 1368px;
$sprite-hermespaketshop-image: '../staticImages/img-sprite.png';
$sprite-hermespaketshop: (0px, 1072px, 0px, -1072px, 62px, 51px, 205px, 1368px, '../staticImages/img-sprite.png', 'sprite-hermespaketshop', );
$sprite-instagram-name: 'sprite-instagram';
$sprite-instagram-x: 0px;
$sprite-instagram-y: 868px;
$sprite-instagram-offset-x: 0px;
$sprite-instagram-offset-y: -868px;
$sprite-instagram-width: 36px;
$sprite-instagram-height: 36px;
$sprite-instagram-total-width: 205px;
$sprite-instagram-total-height: 1368px;
$sprite-instagram-image: '../staticImages/img-sprite.png';
$sprite-instagram: (0px, 868px, 0px, -868px, 36px, 36px, 205px, 1368px, '../staticImages/img-sprite.png', 'sprite-instagram', );
$sprite-mastercard-name: 'sprite-mastercard';
$sprite-mastercard-x: 0px;
$sprite-mastercard-y: 213px;
$sprite-mastercard-offset-x: 0px;
$sprite-mastercard-offset-y: -213px;
$sprite-mastercard-width: 45px;
$sprite-mastercard-height: 33px;
$sprite-mastercard-total-width: 205px;
$sprite-mastercard-total-height: 1368px;
$sprite-mastercard-image: '../staticImages/img-sprite.png';
$sprite-mastercard: (0px, 213px, 0px, -213px, 45px, 33px, 205px, 1368px, '../staticImages/img-sprite.png', 'sprite-mastercard', );
$sprite-milesmore-name: 'sprite-milesmore';
$sprite-milesmore-x: 0px;
$sprite-milesmore-y: 446px;
$sprite-milesmore-offset-x: 0px;
$sprite-milesmore-offset-y: -446px;
$sprite-milesmore-width: 118px;
$sprite-milesmore-height: 34px;
$sprite-milesmore-total-width: 205px;
$sprite-milesmore-total-height: 1368px;
$sprite-milesmore-image: '../staticImages/img-sprite.png';
$sprite-milesmore: (0px, 446px, 0px, -446px, 118px, 34px, 205px, 1368px, '../staticImages/img-sprite.png', 'sprite-milesmore', );
$sprite-milesmorecheckout-name: 'sprite-milesmorecheckout';
$sprite-milesmorecheckout-x: 0px;
$sprite-milesmorecheckout-y: 690px;
$sprite-milesmorecheckout-offset-x: 0px;
$sprite-milesmorecheckout-offset-y: -690px;
$sprite-milesmorecheckout-width: 51px;
$sprite-milesmorecheckout-height: 35px;
$sprite-milesmorecheckout-total-width: 205px;
$sprite-milesmorecheckout-total-height: 1368px;
$sprite-milesmorecheckout-image: '../staticImages/img-sprite.png';
$sprite-milesmorecheckout: (0px, 690px, 0px, -690px, 51px, 35px, 205px, 1368px, '../staticImages/img-sprite.png', 'sprite-milesmorecheckout', );
$sprite-organic-name: 'sprite-organic';
$sprite-organic-x: 0px;
$sprite-organic-y: 120px;
$sprite-organic-offset-x: 0px;
$sprite-organic-offset-y: -120px;
$sprite-organic-width: 41px;
$sprite-organic-height: 27px;
$sprite-organic-total-width: 205px;
$sprite-organic-total-height: 1368px;
$sprite-organic-image: '../staticImages/img-sprite.png';
$sprite-organic: (0px, 120px, 0px, -120px, 41px, 27px, 205px, 1368px, '../staticImages/img-sprite.png', 'sprite-organic', );
$sprite-packstation-name: 'sprite-packstation';
$sprite-packstation-x: 0px;
$sprite-packstation-y: 0px;
$sprite-packstation-offset-x: 0px;
$sprite-packstation-offset-y: 0px;
$sprite-packstation-width: 87px;
$sprite-packstation-height: 19px;
$sprite-packstation-total-width: 205px;
$sprite-packstation-total-height: 1368px;
$sprite-packstation-image: '../staticImages/img-sprite.png';
$sprite-packstation: (0px, 0px, 0px, 0px, 87px, 19px, 205px, 1368px, '../staticImages/img-sprite.png', 'sprite-packstation', );
$sprite-paypal-name: 'sprite-paypal';
$sprite-paypal-x: 0px;
$sprite-paypal-y: 246px;
$sprite-paypal-offset-x: 0px;
$sprite-paypal-offset-y: -246px;
$sprite-paypal-width: 45px;
$sprite-paypal-height: 33px;
$sprite-paypal-total-width: 205px;
$sprite-paypal-total-height: 1368px;
$sprite-paypal-image: '../staticImages/img-sprite.png';
$sprite-paypal: (0px, 246px, 0px, -246px, 45px, 33px, 205px, 1368px, '../staticImages/img-sprite.png', 'sprite-paypal', );
$sprite-paypalcheckout-name: 'sprite-paypalcheckout';
$sprite-paypalcheckout-x: 0px;
$sprite-paypalcheckout-y: 979px;
$sprite-paypalcheckout-offset-x: 0px;
$sprite-paypalcheckout-offset-y: -979px;
$sprite-paypalcheckout-width: 205px;
$sprite-paypalcheckout-height: 44px;
$sprite-paypalcheckout-total-width: 205px;
$sprite-paypalcheckout-total-height: 1368px;
$sprite-paypalcheckout-image: '../staticImages/img-sprite.png';
$sprite-paypalcheckout: (0px, 979px, 0px, -979px, 205px, 44px, 205px, 1368px, '../staticImages/img-sprite.png', 'sprite-paypalcheckout', );
$sprite-paypalexpress-name: 'sprite-paypalexpress';
$sprite-paypalexpress-x: 0px;
$sprite-paypalexpress-y: 904px;
$sprite-paypalexpress-offset-x: 0px;
$sprite-paypalexpress-offset-y: -904px;
$sprite-paypalexpress-width: 64px;
$sprite-paypalexpress-height: 37px;
$sprite-paypalexpress-total-width: 205px;
$sprite-paypalexpress-total-height: 1368px;
$sprite-paypalexpress-image: '../staticImages/img-sprite.png';
$sprite-paypalexpress: (0px, 904px, 0px, -904px, 64px, 37px, 205px, 1368px, '../staticImages/img-sprite.png', 'sprite-paypalexpress', );
$sprite-rechnung-name: 'sprite-rechnung';
$sprite-rechnung-x: 0px;
$sprite-rechnung-y: 279px;
$sprite-rechnung-offset-x: 0px;
$sprite-rechnung-offset-y: -279px;
$sprite-rechnung-width: 45px;
$sprite-rechnung-height: 33px;
$sprite-rechnung-total-width: 205px;
$sprite-rechnung-total-height: 1368px;
$sprite-rechnung-image: '../staticImages/img-sprite.png';
$sprite-rechnung: (0px, 279px, 0px, -279px, 45px, 33px, 205px, 1368px, '../staticImages/img-sprite.png', 'sprite-rechnung', );
$sprite-sepa-name: 'sprite-sepa';
$sprite-sepa-x: 0px;
$sprite-sepa-y: 312px;
$sprite-sepa-offset-x: 0px;
$sprite-sepa-offset-y: -312px;
$sprite-sepa-width: 45px;
$sprite-sepa-height: 33px;
$sprite-sepa-total-width: 205px;
$sprite-sepa-total-height: 1368px;
$sprite-sepa-image: '../staticImages/img-sprite.png';
$sprite-sepa: (0px, 312px, 0px, -312px, 45px, 33px, 205px, 1368px, '../staticImages/img-sprite.png', 'sprite-sepa', );
$sprite-trusted-shop-name: 'sprite-trusted-shop';
$sprite-trusted-shop-x: 0px;
$sprite-trusted-shop-y: 1123px;
$sprite-trusted-shop-offset-x: 0px;
$sprite-trusted-shop-offset-y: -1123px;
$sprite-trusted-shop-width: 55px;
$sprite-trusted-shop-height: 55px;
$sprite-trusted-shop-total-width: 205px;
$sprite-trusted-shop-total-height: 1368px;
$sprite-trusted-shop-image: '../staticImages/img-sprite.png';
$sprite-trusted-shop: (0px, 1123px, 0px, -1123px, 55px, 55px, 205px, 1368px, '../staticImages/img-sprite.png', 'sprite-trusted-shop', );
$sprite-trusted-shopsm-name: 'sprite-trusted-shopsm';
$sprite-trusted-shopsm-x: 0px;
$sprite-trusted-shopsm-y: 725px;
$sprite-trusted-shopsm-offset-x: 0px;
$sprite-trusted-shopsm-offset-y: -725px;
$sprite-trusted-shopsm-width: 35px;
$sprite-trusted-shopsm-height: 35px;
$sprite-trusted-shopsm-total-width: 205px;
$sprite-trusted-shopsm-total-height: 1368px;
$sprite-trusted-shopsm-image: '../staticImages/img-sprite.png';
$sprite-trusted-shopsm: (0px, 725px, 0px, -725px, 35px, 35px, 205px, 1368px, '../staticImages/img-sprite.png', 'sprite-trusted-shopsm', );
$sprite-trustpilot-name: 'sprite-trustpilot';
$sprite-trustpilot-x: 0px;
$sprite-trustpilot-y: 1023px;
$sprite-trustpilot-offset-x: 0px;
$sprite-trustpilot-offset-y: -1023px;
$sprite-trustpilot-width: 100px;
$sprite-trustpilot-height: 49px;
$sprite-trustpilot-total-width: 205px;
$sprite-trustpilot-total-height: 1368px;
$sprite-trustpilot-image: '../staticImages/img-sprite.png';
$sprite-trustpilot: (0px, 1023px, 0px, -1023px, 100px, 49px, 205px, 1368px, '../staticImages/img-sprite.png', 'sprite-trustpilot', );
$sprite-visa-name: 'sprite-visa';
$sprite-visa-x: 0px;
$sprite-visa-y: 345px;
$sprite-visa-offset-x: 0px;
$sprite-visa-offset-y: -345px;
$sprite-visa-width: 45px;
$sprite-visa-height: 33px;
$sprite-visa-total-width: 205px;
$sprite-visa-total-height: 1368px;
$sprite-visa-image: '../staticImages/img-sprite.png';
$sprite-visa: (0px, 345px, 0px, -345px, 45px, 33px, 205px, 1368px, '../staticImages/img-sprite.png', 'sprite-visa', );
$sprite-wineinmoderation-name: 'sprite-wineinmoderation';
$sprite-wineinmoderation-x: 0px;
$sprite-wineinmoderation-y: 41px;
$sprite-wineinmoderation-offset-x: 0px;
$sprite-wineinmoderation-offset-y: -41px;
$sprite-wineinmoderation-width: 118px;
$sprite-wineinmoderation-height: 25px;
$sprite-wineinmoderation-total-width: 205px;
$sprite-wineinmoderation-total-height: 1368px;
$sprite-wineinmoderation-image: '../staticImages/img-sprite.png';
$sprite-wineinmoderation: (0px, 41px, 0px, -41px, 118px, 25px, 205px, 1368px, '../staticImages/img-sprite.png', 'sprite-wineinmoderation', );
$sprite-amex-2x-name: 'sprite-amex-2x';
$sprite-amex-2x-x: 0px;
$sprite-amex-2x-y: 294px;
$sprite-amex-2x-offset-x: 0px;
$sprite-amex-2x-offset-y: -294px;
$sprite-amex-2x-width: 90px;
$sprite-amex-2x-height: 66px;
$sprite-amex-2x-total-width: 410px;
$sprite-amex-2x-total-height: 2736px;
$sprite-amex-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-amex-2x: (0px, 294px, 0px, -294px, 90px, 66px, 410px, 2736px, '../staticImages/img-sprite-2x.png', 'sprite-amex-2x', );
$sprite-apple-pay-2x-name: 'sprite-apple-pay-2x';
$sprite-apple-pay-2x-x: 0px;
$sprite-apple-pay-2x-y: 1520px;
$sprite-apple-pay-2x-offset-x: 0px;
$sprite-apple-pay-2x-offset-y: -1520px;
$sprite-apple-pay-2x-width: 102px;
$sprite-apple-pay-2x-height: 72px;
$sprite-apple-pay-2x-total-width: 410px;
$sprite-apple-pay-2x-total-height: 2736px;
$sprite-apple-pay-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-apple-pay-2x: (0px, 1520px, 0px, -1520px, 102px, 72px, 410px, 2736px, '../staticImages/img-sprite-2x.png', 'sprite-apple-pay-2x', );
$sprite-appstore-2x-name: 'sprite-appstore-2x';
$sprite-appstore-2x-x: 0px;
$sprite-appstore-2x-y: 756px;
$sprite-appstore-2x-offset-x: 0px;
$sprite-appstore-2x-offset-y: -756px;
$sprite-appstore-2x-width: 204px;
$sprite-appstore-2x-height: 68px;
$sprite-appstore-2x-total-width: 410px;
$sprite-appstore-2x-total-height: 2736px;
$sprite-appstore-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-appstore-2x: (0px, 756px, 0px, -756px, 204px, 68px, 410px, 2736px, '../staticImages/img-sprite-2x.png', 'sprite-appstore-2x', );
$sprite-bester-online-wein-2x-name: 'sprite-besterOnlineWein-2x';
$sprite-bester-online-wein-2x-x: 0px;
$sprite-bester-online-wein-2x-y: 2528px;
$sprite-bester-online-wein-2x-offset-x: 0px;
$sprite-bester-online-wein-2x-offset-y: -2528px;
$sprite-bester-online-wein-2x-width: 200px;
$sprite-bester-online-wein-2x-height: 208px;
$sprite-bester-online-wein-2x-total-width: 410px;
$sprite-bester-online-wein-2x-total-height: 2736px;
$sprite-bester-online-wein-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-bester-online-wein-2x: (0px, 2528px, 0px, -2528px, 200px, 208px, 410px, 2736px, '../staticImages/img-sprite-2x.png', 'sprite-besterOnlineWein-2x', );
$sprite-creditcard-2x-name: 'sprite-creditcard-2x';
$sprite-creditcard-2x-x: 0px;
$sprite-creditcard-2x-y: 360px;
$sprite-creditcard-2x-offset-x: 0px;
$sprite-creditcard-2x-offset-y: -360px;
$sprite-creditcard-2x-width: 90px;
$sprite-creditcard-2x-height: 66px;
$sprite-creditcard-2x-total-width: 410px;
$sprite-creditcard-2x-total-height: 2736px;
$sprite-creditcard-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-creditcard-2x: (0px, 360px, 0px, -360px, 90px, 66px, 410px, 2736px, '../staticImages/img-sprite-2x.png', 'sprite-creditcard-2x', );
$sprite-creditcards-wide-2x-name: 'sprite-creditcards-wide-2x';
$sprite-creditcards-wide-2x-x: 0px;
$sprite-creditcards-wide-2x-y: 38px;
$sprite-creditcards-wide-2x-offset-x: 0px;
$sprite-creditcards-wide-2x-offset-y: -38px;
$sprite-creditcards-wide-2x-width: 220px;
$sprite-creditcards-wide-2x-height: 44px;
$sprite-creditcards-wide-2x-total-width: 410px;
$sprite-creditcards-wide-2x-total-height: 2736px;
$sprite-creditcards-wide-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-creditcards-wide-2x: (0px, 38px, 0px, -38px, 220px, 44px, 410px, 2736px, '../staticImages/img-sprite-2x.png', 'sprite-creditcards-wide-2x', );
$sprite-dhl-2x-name: 'sprite-dhl-2x';
$sprite-dhl-2x-x: 0px;
$sprite-dhl-2x-y: 132px;
$sprite-dhl-2x-offset-x: 0px;
$sprite-dhl-2x-offset-y: -132px;
$sprite-dhl-2x-width: 244px;
$sprite-dhl-2x-height: 54px;
$sprite-dhl-2x-total-width: 410px;
$sprite-dhl-2x-total-height: 2736px;
$sprite-dhl-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-dhl-2x: (0px, 132px, 0px, -132px, 244px, 54px, 410px, 2736px, '../staticImages/img-sprite-2x.png', 'sprite-dhl-2x', );
$sprite-elbphilharmonie-2x-name: 'sprite-elbphilharmonie-2x';
$sprite-elbphilharmonie-2x-x: 0px;
$sprite-elbphilharmonie-2x-y: 1882px;
$sprite-elbphilharmonie-2x-offset-x: 0px;
$sprite-elbphilharmonie-2x-offset-y: -1882px;
$sprite-elbphilharmonie-2x-width: 236px;
$sprite-elbphilharmonie-2x-height: 76px;
$sprite-elbphilharmonie-2x-total-width: 410px;
$sprite-elbphilharmonie-2x-total-height: 2736px;
$sprite-elbphilharmonie-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-elbphilharmonie-2x: (0px, 1882px, 0px, -1882px, 236px, 76px, 410px, 2736px, '../staticImages/img-sprite-2x.png', 'sprite-elbphilharmonie-2x', );
$sprite-facebook-2x-name: 'sprite-facebook-2x';
$sprite-facebook-2x-x: 0px;
$sprite-facebook-2x-y: 1592px;
$sprite-facebook-2x-offset-x: 0px;
$sprite-facebook-2x-offset-y: -1592px;
$sprite-facebook-2x-width: 72px;
$sprite-facebook-2x-height: 72px;
$sprite-facebook-2x-total-width: 410px;
$sprite-facebook-2x-total-height: 2736px;
$sprite-facebook-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-facebook-2x: (0px, 1592px, 0px, -1592px, 72px, 72px, 410px, 2736px, '../staticImages/img-sprite-2x.png', 'sprite-facebook-2x', );
$sprite-forecast-dhl-2x-name: 'sprite-forecast-dhl-2x';
$sprite-forecast-dhl-2x-x: 0px;
$sprite-forecast-dhl-2x-y: 960px;
$sprite-forecast-dhl-2x-offset-x: 0px;
$sprite-forecast-dhl-2x-offset-y: -960px;
$sprite-forecast-dhl-2x-width: 102px;
$sprite-forecast-dhl-2x-height: 70px;
$sprite-forecast-dhl-2x-total-width: 410px;
$sprite-forecast-dhl-2x-total-height: 2736px;
$sprite-forecast-dhl-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-forecast-dhl-2x: (0px, 960px, 0px, -960px, 102px, 70px, 410px, 2736px, '../staticImages/img-sprite-2x.png', 'sprite-forecast-dhl-2x', );
$sprite-forecast-dhl-eu-2x-name: 'sprite-forecast-dhl_eu-2x';
$sprite-forecast-dhl-eu-2x-x: 0px;
$sprite-forecast-dhl-eu-2x-y: 1030px;
$sprite-forecast-dhl-eu-2x-offset-x: 0px;
$sprite-forecast-dhl-eu-2x-offset-y: -1030px;
$sprite-forecast-dhl-eu-2x-width: 102px;
$sprite-forecast-dhl-eu-2x-height: 70px;
$sprite-forecast-dhl-eu-2x-total-width: 410px;
$sprite-forecast-dhl-eu-2x-total-height: 2736px;
$sprite-forecast-dhl-eu-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-forecast-dhl-eu-2x: (0px, 1030px, 0px, -1030px, 102px, 70px, 410px, 2736px, '../staticImages/img-sprite-2x.png', 'sprite-forecast-dhl_eu-2x', );
$sprite-forecast-dhl-express-2x-name: 'sprite-forecast-dhl_express-2x';
$sprite-forecast-dhl-express-2x-x: 0px;
$sprite-forecast-dhl-express-2x-y: 1100px;
$sprite-forecast-dhl-express-2x-offset-x: 0px;
$sprite-forecast-dhl-express-2x-offset-y: -1100px;
$sprite-forecast-dhl-express-2x-width: 102px;
$sprite-forecast-dhl-express-2x-height: 70px;
$sprite-forecast-dhl-express-2x-total-width: 410px;
$sprite-forecast-dhl-express-2x-total-height: 2736px;
$sprite-forecast-dhl-express-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-forecast-dhl-express-2x: (0px, 1100px, 0px, -1100px, 102px, 70px, 410px, 2736px, '../staticImages/img-sprite-2x.png', 'sprite-forecast-dhl_express-2x', );
$sprite-forecast-hermes-2x-name: 'sprite-forecast-hermes-2x';
$sprite-forecast-hermes-2x-x: 0px;
$sprite-forecast-hermes-2x-y: 1170px;
$sprite-forecast-hermes-2x-offset-x: 0px;
$sprite-forecast-hermes-2x-offset-y: -1170px;
$sprite-forecast-hermes-2x-width: 102px;
$sprite-forecast-hermes-2x-height: 70px;
$sprite-forecast-hermes-2x-total-width: 410px;
$sprite-forecast-hermes-2x-total-height: 2736px;
$sprite-forecast-hermes-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-forecast-hermes-2x: (0px, 1170px, 0px, -1170px, 102px, 70px, 410px, 2736px, '../staticImages/img-sprite-2x.png', 'sprite-forecast-hermes-2x', );
$sprite-forecast-unknown-2x-name: 'sprite-forecast-unknown-2x';
$sprite-forecast-unknown-2x-x: 0px;
$sprite-forecast-unknown-2x-y: 1240px;
$sprite-forecast-unknown-2x-offset-x: 0px;
$sprite-forecast-unknown-2x-offset-y: -1240px;
$sprite-forecast-unknown-2x-width: 102px;
$sprite-forecast-unknown-2x-height: 70px;
$sprite-forecast-unknown-2x-total-width: 410px;
$sprite-forecast-unknown-2x-total-height: 2736px;
$sprite-forecast-unknown-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-forecast-unknown-2x: (0px, 1240px, 0px, -1240px, 102px, 70px, 410px, 2736px, '../staticImages/img-sprite-2x.png', 'sprite-forecast-unknown-2x', );
$sprite-giftvoucher-2x-name: 'sprite-giftvoucher-2x';
$sprite-giftvoucher-2x-x: 0px;
$sprite-giftvoucher-2x-y: 1664px;
$sprite-giftvoucher-2x-offset-x: 0px;
$sprite-giftvoucher-2x-offset-y: -1664px;
$sprite-giftvoucher-2x-width: 102px;
$sprite-giftvoucher-2x-height: 72px;
$sprite-giftvoucher-2x-total-width: 410px;
$sprite-giftvoucher-2x-total-height: 2736px;
$sprite-giftvoucher-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-giftvoucher-2x: (0px, 1664px, 0px, -1664px, 102px, 72px, 410px, 2736px, '../staticImages/img-sprite-2x.png', 'sprite-giftvoucher-2x', );
$sprite-googleplay-2x-name: 'sprite-googleplay-2x';
$sprite-googleplay-2x-x: 0px;
$sprite-googleplay-2x-y: 824px;
$sprite-googleplay-2x-offset-x: 0px;
$sprite-googleplay-2x-offset-y: -824px;
$sprite-googleplay-2x-width: 230px;
$sprite-googleplay-2x-height: 68px;
$sprite-googleplay-2x-total-width: 410px;
$sprite-googleplay-2x-total-height: 2736px;
$sprite-googleplay-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-googleplay-2x: (0px, 824px, 0px, -824px, 230px, 68px, 410px, 2736px, '../staticImages/img-sprite-2x.png', 'sprite-googleplay-2x', );
$sprite-heart-finger-2x-name: 'sprite-heart-finger-2x';
$sprite-heart-finger-2x-x: 0px;
$sprite-heart-finger-2x-y: 2356px;
$sprite-heart-finger-2x-offset-x: 0px;
$sprite-heart-finger-2x-offset-y: -2356px;
$sprite-heart-finger-2x-width: 116px;
$sprite-heart-finger-2x-height: 172px;
$sprite-heart-finger-2x-total-width: 410px;
$sprite-heart-finger-2x-total-height: 2736px;
$sprite-heart-finger-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-heart-finger-2x: (0px, 2356px, 0px, -2356px, 116px, 172px, 410px, 2736px, '../staticImages/img-sprite-2x.png', 'sprite-heart-finger-2x', );
$sprite-hermes-2x-name: 'sprite-hermes-2x';
$sprite-hermes-2x-x: 0px;
$sprite-hermes-2x-y: 186px;
$sprite-hermes-2x-offset-x: 0px;
$sprite-hermes-2x-offset-y: -186px;
$sprite-hermes-2x-width: 244px;
$sprite-hermes-2x-height: 54px;
$sprite-hermes-2x-total-width: 410px;
$sprite-hermes-2x-total-height: 2736px;
$sprite-hermes-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-hermes-2x: (0px, 186px, 0px, -186px, 244px, 54px, 410px, 2736px, '../staticImages/img-sprite-2x.png', 'sprite-hermes-2x', );
$sprite-hermeslogo-2x-name: 'sprite-hermeslogo-2x';
$sprite-hermeslogo-2x-x: 0px;
$sprite-hermeslogo-2x-y: 1310px;
$sprite-hermeslogo-2x-offset-x: 0px;
$sprite-hermeslogo-2x-offset-y: -1310px;
$sprite-hermeslogo-2x-width: 160px;
$sprite-hermeslogo-2x-height: 70px;
$sprite-hermeslogo-2x-total-width: 410px;
$sprite-hermeslogo-2x-total-height: 2736px;
$sprite-hermeslogo-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-hermeslogo-2x: (0px, 1310px, 0px, -1310px, 160px, 70px, 410px, 2736px, '../staticImages/img-sprite-2x.png', 'sprite-hermeslogo-2x', );
$sprite-hermespaketshop-2x-name: 'sprite-hermespaketshop-2x';
$sprite-hermespaketshop-2x-x: 0px;
$sprite-hermespaketshop-2x-y: 2144px;
$sprite-hermespaketshop-2x-offset-x: 0px;
$sprite-hermespaketshop-2x-offset-y: -2144px;
$sprite-hermespaketshop-2x-width: 124px;
$sprite-hermespaketshop-2x-height: 102px;
$sprite-hermespaketshop-2x-total-width: 410px;
$sprite-hermespaketshop-2x-total-height: 2736px;
$sprite-hermespaketshop-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-hermespaketshop-2x: (0px, 2144px, 0px, -2144px, 124px, 102px, 410px, 2736px, '../staticImages/img-sprite-2x.png', 'sprite-hermespaketshop-2x', );
$sprite-instagram-2x-name: 'sprite-instagram-2x';
$sprite-instagram-2x-x: 0px;
$sprite-instagram-2x-y: 1736px;
$sprite-instagram-2x-offset-x: 0px;
$sprite-instagram-2x-offset-y: -1736px;
$sprite-instagram-2x-width: 72px;
$sprite-instagram-2x-height: 72px;
$sprite-instagram-2x-total-width: 410px;
$sprite-instagram-2x-total-height: 2736px;
$sprite-instagram-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-instagram-2x: (0px, 1736px, 0px, -1736px, 72px, 72px, 410px, 2736px, '../staticImages/img-sprite-2x.png', 'sprite-instagram-2x', );
$sprite-mastercard-2x-name: 'sprite-mastercard-2x';
$sprite-mastercard-2x-x: 0px;
$sprite-mastercard-2x-y: 426px;
$sprite-mastercard-2x-offset-x: 0px;
$sprite-mastercard-2x-offset-y: -426px;
$sprite-mastercard-2x-width: 90px;
$sprite-mastercard-2x-height: 66px;
$sprite-mastercard-2x-total-width: 410px;
$sprite-mastercard-2x-total-height: 2736px;
$sprite-mastercard-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-mastercard-2x: (0px, 426px, 0px, -426px, 90px, 66px, 410px, 2736px, '../staticImages/img-sprite-2x.png', 'sprite-mastercard-2x', );
$sprite-milesmore-2x-name: 'sprite-milesmore-2x';
$sprite-milesmore-2x-x: 0px;
$sprite-milesmore-2x-y: 892px;
$sprite-milesmore-2x-offset-x: 0px;
$sprite-milesmore-2x-offset-y: -892px;
$sprite-milesmore-2x-width: 236px;
$sprite-milesmore-2x-height: 68px;
$sprite-milesmore-2x-total-width: 410px;
$sprite-milesmore-2x-total-height: 2736px;
$sprite-milesmore-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-milesmore-2x: (0px, 892px, 0px, -892px, 236px, 68px, 410px, 2736px, '../staticImages/img-sprite-2x.png', 'sprite-milesmore-2x', );
$sprite-milesmorecheckout-2x-name: 'sprite-milesmorecheckout-2x';
$sprite-milesmorecheckout-2x-x: 0px;
$sprite-milesmorecheckout-2x-y: 1380px;
$sprite-milesmorecheckout-2x-offset-x: 0px;
$sprite-milesmorecheckout-2x-offset-y: -1380px;
$sprite-milesmorecheckout-2x-width: 102px;
$sprite-milesmorecheckout-2x-height: 70px;
$sprite-milesmorecheckout-2x-total-width: 410px;
$sprite-milesmorecheckout-2x-total-height: 2736px;
$sprite-milesmorecheckout-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-milesmorecheckout-2x: (0px, 1380px, 0px, -1380px, 102px, 70px, 410px, 2736px, '../staticImages/img-sprite-2x.png', 'sprite-milesmorecheckout-2x', );
$sprite-organic-2x-name: 'sprite-organic-2x';
$sprite-organic-2x-x: 0px;
$sprite-organic-2x-y: 240px;
$sprite-organic-2x-offset-x: 0px;
$sprite-organic-2x-offset-y: -240px;
$sprite-organic-2x-width: 82px;
$sprite-organic-2x-height: 54px;
$sprite-organic-2x-total-width: 410px;
$sprite-organic-2x-total-height: 2736px;
$sprite-organic-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-organic-2x: (0px, 240px, 0px, -240px, 82px, 54px, 410px, 2736px, '../staticImages/img-sprite-2x.png', 'sprite-organic-2x', );
$sprite-packstation-2x-name: 'sprite-packstation-2x';
$sprite-packstation-2x-x: 0px;
$sprite-packstation-2x-y: 0px;
$sprite-packstation-2x-offset-x: 0px;
$sprite-packstation-2x-offset-y: 0px;
$sprite-packstation-2x-width: 174px;
$sprite-packstation-2x-height: 38px;
$sprite-packstation-2x-total-width: 410px;
$sprite-packstation-2x-total-height: 2736px;
$sprite-packstation-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-packstation-2x: (0px, 0px, 0px, 0px, 174px, 38px, 410px, 2736px, '../staticImages/img-sprite-2x.png', 'sprite-packstation-2x', );
$sprite-paypal-2x-name: 'sprite-paypal-2x';
$sprite-paypal-2x-x: 0px;
$sprite-paypal-2x-y: 492px;
$sprite-paypal-2x-offset-x: 0px;
$sprite-paypal-2x-offset-y: -492px;
$sprite-paypal-2x-width: 90px;
$sprite-paypal-2x-height: 66px;
$sprite-paypal-2x-total-width: 410px;
$sprite-paypal-2x-total-height: 2736px;
$sprite-paypal-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-paypal-2x: (0px, 492px, 0px, -492px, 90px, 66px, 410px, 2736px, '../staticImages/img-sprite-2x.png', 'sprite-paypal-2x', );
$sprite-paypalcheckout-2x-name: 'sprite-paypalcheckout-2x';
$sprite-paypalcheckout-2x-x: 0px;
$sprite-paypalcheckout-2x-y: 1958px;
$sprite-paypalcheckout-2x-offset-x: 0px;
$sprite-paypalcheckout-2x-offset-y: -1958px;
$sprite-paypalcheckout-2x-width: 410px;
$sprite-paypalcheckout-2x-height: 88px;
$sprite-paypalcheckout-2x-total-width: 410px;
$sprite-paypalcheckout-2x-total-height: 2736px;
$sprite-paypalcheckout-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-paypalcheckout-2x: (0px, 1958px, 0px, -1958px, 410px, 88px, 410px, 2736px, '../staticImages/img-sprite-2x.png', 'sprite-paypalcheckout-2x', );
$sprite-paypalexpress-2x-name: 'sprite-paypalexpress-2x';
$sprite-paypalexpress-2x-x: 0px;
$sprite-paypalexpress-2x-y: 1808px;
$sprite-paypalexpress-2x-offset-x: 0px;
$sprite-paypalexpress-2x-offset-y: -1808px;
$sprite-paypalexpress-2x-width: 128px;
$sprite-paypalexpress-2x-height: 74px;
$sprite-paypalexpress-2x-total-width: 410px;
$sprite-paypalexpress-2x-total-height: 2736px;
$sprite-paypalexpress-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-paypalexpress-2x: (0px, 1808px, 0px, -1808px, 128px, 74px, 410px, 2736px, '../staticImages/img-sprite-2x.png', 'sprite-paypalexpress-2x', );
$sprite-rechnung-2x-name: 'sprite-rechnung-2x';
$sprite-rechnung-2x-x: 0px;
$sprite-rechnung-2x-y: 558px;
$sprite-rechnung-2x-offset-x: 0px;
$sprite-rechnung-2x-offset-y: -558px;
$sprite-rechnung-2x-width: 90px;
$sprite-rechnung-2x-height: 66px;
$sprite-rechnung-2x-total-width: 410px;
$sprite-rechnung-2x-total-height: 2736px;
$sprite-rechnung-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-rechnung-2x: (0px, 558px, 0px, -558px, 90px, 66px, 410px, 2736px, '../staticImages/img-sprite-2x.png', 'sprite-rechnung-2x', );
$sprite-sepa-2x-name: 'sprite-sepa-2x';
$sprite-sepa-2x-x: 0px;
$sprite-sepa-2x-y: 624px;
$sprite-sepa-2x-offset-x: 0px;
$sprite-sepa-2x-offset-y: -624px;
$sprite-sepa-2x-width: 90px;
$sprite-sepa-2x-height: 66px;
$sprite-sepa-2x-total-width: 410px;
$sprite-sepa-2x-total-height: 2736px;
$sprite-sepa-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-sepa-2x: (0px, 624px, 0px, -624px, 90px, 66px, 410px, 2736px, '../staticImages/img-sprite-2x.png', 'sprite-sepa-2x', );
$sprite-trusted-shop-2x-name: 'sprite-trusted-shop-2x';
$sprite-trusted-shop-2x-x: 0px;
$sprite-trusted-shop-2x-y: 2246px;
$sprite-trusted-shop-2x-offset-x: 0px;
$sprite-trusted-shop-2x-offset-y: -2246px;
$sprite-trusted-shop-2x-width: 110px;
$sprite-trusted-shop-2x-height: 110px;
$sprite-trusted-shop-2x-total-width: 410px;
$sprite-trusted-shop-2x-total-height: 2736px;
$sprite-trusted-shop-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-trusted-shop-2x: (0px, 2246px, 0px, -2246px, 110px, 110px, 410px, 2736px, '../staticImages/img-sprite-2x.png', 'sprite-trusted-shop-2x', );
$sprite-trusted-shopsm-2x-name: 'sprite-trusted-shopsm-2x';
$sprite-trusted-shopsm-2x-x: 0px;
$sprite-trusted-shopsm-2x-y: 1450px;
$sprite-trusted-shopsm-2x-offset-x: 0px;
$sprite-trusted-shopsm-2x-offset-y: -1450px;
$sprite-trusted-shopsm-2x-width: 70px;
$sprite-trusted-shopsm-2x-height: 70px;
$sprite-trusted-shopsm-2x-total-width: 410px;
$sprite-trusted-shopsm-2x-total-height: 2736px;
$sprite-trusted-shopsm-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-trusted-shopsm-2x: (0px, 1450px, 0px, -1450px, 70px, 70px, 410px, 2736px, '../staticImages/img-sprite-2x.png', 'sprite-trusted-shopsm-2x', );
$sprite-trustpilot-2x-name: 'sprite-trustpilot-2x';
$sprite-trustpilot-2x-x: 0px;
$sprite-trustpilot-2x-y: 2046px;
$sprite-trustpilot-2x-offset-x: 0px;
$sprite-trustpilot-2x-offset-y: -2046px;
$sprite-trustpilot-2x-width: 200px;
$sprite-trustpilot-2x-height: 98px;
$sprite-trustpilot-2x-total-width: 410px;
$sprite-trustpilot-2x-total-height: 2736px;
$sprite-trustpilot-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-trustpilot-2x: (0px, 2046px, 0px, -2046px, 200px, 98px, 410px, 2736px, '../staticImages/img-sprite-2x.png', 'sprite-trustpilot-2x', );
$sprite-visa-2x-name: 'sprite-visa-2x';
$sprite-visa-2x-x: 0px;
$sprite-visa-2x-y: 690px;
$sprite-visa-2x-offset-x: 0px;
$sprite-visa-2x-offset-y: -690px;
$sprite-visa-2x-width: 90px;
$sprite-visa-2x-height: 66px;
$sprite-visa-2x-total-width: 410px;
$sprite-visa-2x-total-height: 2736px;
$sprite-visa-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-visa-2x: (0px, 690px, 0px, -690px, 90px, 66px, 410px, 2736px, '../staticImages/img-sprite-2x.png', 'sprite-visa-2x', );
$sprite-wineinmoderation-2x-name: 'sprite-wineinmoderation-2x';
$sprite-wineinmoderation-2x-x: 0px;
$sprite-wineinmoderation-2x-y: 82px;
$sprite-wineinmoderation-2x-offset-x: 0px;
$sprite-wineinmoderation-2x-offset-y: -82px;
$sprite-wineinmoderation-2x-width: 236px;
$sprite-wineinmoderation-2x-height: 50px;
$sprite-wineinmoderation-2x-total-width: 410px;
$sprite-wineinmoderation-2x-total-height: 2736px;
$sprite-wineinmoderation-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-wineinmoderation-2x: (0px, 82px, 0px, -82px, 236px, 50px, 410px, 2736px, '../staticImages/img-sprite-2x.png', 'sprite-wineinmoderation-2x', );
$spritesheet-width: 205px;
$spritesheet-height: 1368px;
$spritesheet-image: '../staticImages/img-sprite.png';
$spritesheet-sprites: ($sprite-amex, $sprite-apple-pay, $sprite-appstore, $sprite-bester-online-wein, $sprite-creditcard, $sprite-creditcards-wide, $sprite-dhl, $sprite-elbphilharmonie, $sprite-facebook, $sprite-forecast-dhl, $sprite-forecast-dhl-eu, $sprite-forecast-dhl-express, $sprite-forecast-hermes, $sprite-forecast-unknown, $sprite-giftvoucher, $sprite-googleplay, $sprite-heart-finger, $sprite-hermes, $sprite-hermeslogo, $sprite-hermespaketshop, $sprite-instagram, $sprite-mastercard, $sprite-milesmore, $sprite-milesmorecheckout, $sprite-organic, $sprite-packstation, $sprite-paypal, $sprite-paypalcheckout, $sprite-paypalexpress, $sprite-rechnung, $sprite-sepa, $sprite-trusted-shop, $sprite-trusted-shopsm, $sprite-trustpilot, $sprite-visa, $sprite-wineinmoderation, );
$spritesheet: (205px, 1368px, '../staticImages/img-sprite.png', $spritesheet-sprites, );
$retina-spritesheet-width: 410px;
$retina-spritesheet-height: 2736px;
$retina-spritesheet-image: '../staticImages/img-sprite-2x.png';
$retina-spritesheet-sprites: ($sprite-amex-2x, $sprite-apple-pay-2x, $sprite-appstore-2x, $sprite-bester-online-wein-2x, $sprite-creditcard-2x, $sprite-creditcards-wide-2x, $sprite-dhl-2x, $sprite-elbphilharmonie-2x, $sprite-facebook-2x, $sprite-forecast-dhl-2x, $sprite-forecast-dhl-eu-2x, $sprite-forecast-dhl-express-2x, $sprite-forecast-hermes-2x, $sprite-forecast-unknown-2x, $sprite-giftvoucher-2x, $sprite-googleplay-2x, $sprite-heart-finger-2x, $sprite-hermes-2x, $sprite-hermeslogo-2x, $sprite-hermespaketshop-2x, $sprite-instagram-2x, $sprite-mastercard-2x, $sprite-milesmore-2x, $sprite-milesmorecheckout-2x, $sprite-organic-2x, $sprite-packstation-2x, $sprite-paypal-2x, $sprite-paypalcheckout-2x, $sprite-paypalexpress-2x, $sprite-rechnung-2x, $sprite-sepa-2x, $sprite-trusted-shop-2x, $sprite-trusted-shopsm-2x, $sprite-trustpilot-2x, $sprite-visa-2x, $sprite-wineinmoderation-2x, );
$retina-spritesheet: (410px, 2736px, '../staticImages/img-sprite-2x.png', $retina-spritesheet-sprites, );

// These "retina group" variables are mappings for the naming and pairing of normal and retina sprites.
//
// The list formatted variables are intended for mixins like `retina-sprite` and `retina-sprites`.
$sprite-amex-group-name: 'sprite-amex';
$sprite-amex-group: ('sprite-amex', $sprite-amex, $sprite-amex-2x, );
$sprite-apple-pay-group-name: 'sprite-apple-pay';
$sprite-apple-pay-group: ('sprite-apple-pay', $sprite-apple-pay, $sprite-apple-pay-2x, );
$sprite-appstore-group-name: 'sprite-appstore';
$sprite-appstore-group: ('sprite-appstore', $sprite-appstore, $sprite-appstore-2x, );
$sprite-bester-online-wein-group-name: 'sprite-besterOnlineWein';
$sprite-bester-online-wein-group: ('sprite-besterOnlineWein', $sprite-bester-online-wein, $sprite-bester-online-wein-2x, );
$sprite-creditcard-group-name: 'sprite-creditcard';
$sprite-creditcard-group: ('sprite-creditcard', $sprite-creditcard, $sprite-creditcard-2x, );
$sprite-creditcards-wide-group-name: 'sprite-creditcards-wide';
$sprite-creditcards-wide-group: ('sprite-creditcards-wide', $sprite-creditcards-wide, $sprite-creditcards-wide-2x, );
$sprite-dhl-group-name: 'sprite-dhl';
$sprite-dhl-group: ('sprite-dhl', $sprite-dhl, $sprite-dhl-2x, );
$sprite-elbphilharmonie-group-name: 'sprite-elbphilharmonie';
$sprite-elbphilharmonie-group: ('sprite-elbphilharmonie', $sprite-elbphilharmonie, $sprite-elbphilharmonie-2x, );
$sprite-facebook-group-name: 'sprite-facebook';
$sprite-facebook-group: ('sprite-facebook', $sprite-facebook, $sprite-facebook-2x, );
$sprite-forecast-dhl-group-name: 'sprite-forecast-dhl';
$sprite-forecast-dhl-group: ('sprite-forecast-dhl', $sprite-forecast-dhl, $sprite-forecast-dhl-2x, );
$sprite-forecast-dhl-eu-group-name: 'sprite-forecast-dhl_eu';
$sprite-forecast-dhl-eu-group: ('sprite-forecast-dhl_eu', $sprite-forecast-dhl-eu, $sprite-forecast-dhl-eu-2x, );
$sprite-forecast-dhl-express-group-name: 'sprite-forecast-dhl_express';
$sprite-forecast-dhl-express-group: ('sprite-forecast-dhl_express', $sprite-forecast-dhl-express, $sprite-forecast-dhl-express-2x, );
$sprite-forecast-hermes-group-name: 'sprite-forecast-hermes';
$sprite-forecast-hermes-group: ('sprite-forecast-hermes', $sprite-forecast-hermes, $sprite-forecast-hermes-2x, );
$sprite-forecast-unknown-group-name: 'sprite-forecast-unknown';
$sprite-forecast-unknown-group: ('sprite-forecast-unknown', $sprite-forecast-unknown, $sprite-forecast-unknown-2x, );
$sprite-giftvoucher-group-name: 'sprite-giftvoucher';
$sprite-giftvoucher-group: ('sprite-giftvoucher', $sprite-giftvoucher, $sprite-giftvoucher-2x, );
$sprite-googleplay-group-name: 'sprite-googleplay';
$sprite-googleplay-group: ('sprite-googleplay', $sprite-googleplay, $sprite-googleplay-2x, );
$sprite-heart-finger-group-name: 'sprite-heart-finger';
$sprite-heart-finger-group: ('sprite-heart-finger', $sprite-heart-finger, $sprite-heart-finger-2x, );
$sprite-hermes-group-name: 'sprite-hermes';
$sprite-hermes-group: ('sprite-hermes', $sprite-hermes, $sprite-hermes-2x, );
$sprite-hermeslogo-group-name: 'sprite-hermeslogo';
$sprite-hermeslogo-group: ('sprite-hermeslogo', $sprite-hermeslogo, $sprite-hermeslogo-2x, );
$sprite-hermespaketshop-group-name: 'sprite-hermespaketshop';
$sprite-hermespaketshop-group: ('sprite-hermespaketshop', $sprite-hermespaketshop, $sprite-hermespaketshop-2x, );
$sprite-instagram-group-name: 'sprite-instagram';
$sprite-instagram-group: ('sprite-instagram', $sprite-instagram, $sprite-instagram-2x, );
$sprite-mastercard-group-name: 'sprite-mastercard';
$sprite-mastercard-group: ('sprite-mastercard', $sprite-mastercard, $sprite-mastercard-2x, );
$sprite-milesmore-group-name: 'sprite-milesmore';
$sprite-milesmore-group: ('sprite-milesmore', $sprite-milesmore, $sprite-milesmore-2x, );
$sprite-milesmorecheckout-group-name: 'sprite-milesmorecheckout';
$sprite-milesmorecheckout-group: ('sprite-milesmorecheckout', $sprite-milesmorecheckout, $sprite-milesmorecheckout-2x, );
$sprite-organic-group-name: 'sprite-organic';
$sprite-organic-group: ('sprite-organic', $sprite-organic, $sprite-organic-2x, );
$sprite-packstation-group-name: 'sprite-packstation';
$sprite-packstation-group: ('sprite-packstation', $sprite-packstation, $sprite-packstation-2x, );
$sprite-paypal-group-name: 'sprite-paypal';
$sprite-paypal-group: ('sprite-paypal', $sprite-paypal, $sprite-paypal-2x, );
$sprite-paypalcheckout-group-name: 'sprite-paypalcheckout';
$sprite-paypalcheckout-group: ('sprite-paypalcheckout', $sprite-paypalcheckout, $sprite-paypalcheckout-2x, );
$sprite-paypalexpress-group-name: 'sprite-paypalexpress';
$sprite-paypalexpress-group: ('sprite-paypalexpress', $sprite-paypalexpress, $sprite-paypalexpress-2x, );
$sprite-rechnung-group-name: 'sprite-rechnung';
$sprite-rechnung-group: ('sprite-rechnung', $sprite-rechnung, $sprite-rechnung-2x, );
$sprite-sepa-group-name: 'sprite-sepa';
$sprite-sepa-group: ('sprite-sepa', $sprite-sepa, $sprite-sepa-2x, );
$sprite-trusted-shop-group-name: 'sprite-trusted-shop';
$sprite-trusted-shop-group: ('sprite-trusted-shop', $sprite-trusted-shop, $sprite-trusted-shop-2x, );
$sprite-trusted-shopsm-group-name: 'sprite-trusted-shopsm';
$sprite-trusted-shopsm-group: ('sprite-trusted-shopsm', $sprite-trusted-shopsm, $sprite-trusted-shopsm-2x, );
$sprite-trustpilot-group-name: 'sprite-trustpilot';
$sprite-trustpilot-group: ('sprite-trustpilot', $sprite-trustpilot, $sprite-trustpilot-2x, );
$sprite-visa-group-name: 'sprite-visa';
$sprite-visa-group: ('sprite-visa', $sprite-visa, $sprite-visa-2x, );
$sprite-wineinmoderation-group-name: 'sprite-wineinmoderation';
$sprite-wineinmoderation-group: ('sprite-wineinmoderation', $sprite-wineinmoderation, $sprite-wineinmoderation-2x, );
$retina-groups: ($sprite-amex-group, $sprite-apple-pay-group, $sprite-appstore-group, $sprite-bester-online-wein-group, $sprite-creditcard-group, $sprite-creditcards-wide-group, $sprite-dhl-group, $sprite-elbphilharmonie-group, $sprite-facebook-group, $sprite-forecast-dhl-group, $sprite-forecast-dhl-eu-group, $sprite-forecast-dhl-express-group, $sprite-forecast-hermes-group, $sprite-forecast-unknown-group, $sprite-giftvoucher-group, $sprite-googleplay-group, $sprite-heart-finger-group, $sprite-hermes-group, $sprite-hermeslogo-group, $sprite-hermespaketshop-group, $sprite-instagram-group, $sprite-mastercard-group, $sprite-milesmore-group, $sprite-milesmorecheckout-group, $sprite-organic-group, $sprite-packstation-group, $sprite-paypal-group, $sprite-paypalcheckout-group, $sprite-paypalexpress-group, $sprite-rechnung-group, $sprite-sepa-group, $sprite-trusted-shop-group, $sprite-trusted-shopsm-group, $sprite-trustpilot-group, $sprite-visa-group, $sprite-wineinmoderation-group, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `retina-sprite` mixin sets up rules and a media query for a sprite/retina sprite.
//   It should be used with a "retina group" variable.
//
// The media query is from CSS Tricks: https://css-tricks.com/snippets/css/retina-display-media-query/
//
// $icon-home-group: ('icon-home', $icon-home, $icon-home-2x, );
//
// .icon-home {
//   @include retina-sprite($icon-home-group);
// }
@mixin sprite-background-size($sprite) {
  $sprite-total-width: nth($sprite, 7);
  $sprite-total-height: nth($sprite, 8);
  background-size: $sprite-total-width $sprite-total-height;
}

@mixin retina-sprite($retina-group) {
  $normal-sprite: nth($retina-group, 2);
  $retina-sprite: nth($retina-group, 3);
  @include sprite($normal-sprite);

  @media (-webkit-min-device-pixel-ratio: 2),
         (min-resolution: 192dpi) {
    @include sprite-image($retina-sprite);
    @include sprite-background-size($normal-sprite);
  }
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}

// The `retina-sprites` mixin generates a CSS rule and media query for retina groups
//   This yields the same output as CSS retina template but can be overridden in SCSS
//
// @include retina-sprites($retina-groups);
@mixin retina-sprites($retina-groups) {
  @each $retina-group in $retina-groups {
    $sprite-name: nth($retina-group, 1);
    .#{$sprite-name} {
      @include retina-sprite($retina-group);
    }
  }
}
