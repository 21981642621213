.vue-animation {
  &--fade {
    &-enter-active,
    &-leave-active {
      transition: opacity .5s linear;
    }

    &-enter-from,
    &-leave-to {
      opacity: 0;
    }
  }

  &--fade-delayed {
    &-enter-active,
    &-leave-active {
      transition: opacity .2s;
      transition-delay: .5s;
    }

    &-enter-from,
    &-leave-to {
      opacity: 0;
    }
  }

  &--move {
    &-enter-active,
    &-leave-active {
      transition: transform .3s, opacity .3s;
    }

    &-enter-from,
    &-leave-to {
      opacity: 0;
      transform: translateY(-16px);
    }
  }

  &--list {
    &-enter-active,
    &-leave-active,
    &-move {
      transition: .37s cubic-bezier(.59, .12, .34, .95);
      transition-property: opacity, transform;
    }

    &-enter-from {
      opacity: 0;
      transform: translateX(-50px);
    }

    &-enter-to {
      opacity: 1;
      transform: translateX(0);
    }

    &-leave-from {
      opacity: 1;
      transform: translateX(0);
    }

    &-leave-to {
      opacity: 0;
      transform: translateX(50px);
    }
  }

  &--spin-fade {
    &-enter-active {
      transition: opacity .2s ease-in, transform .25s cubic-bezier(.75, 2, .6, .55);

      .is_safari & {
        transition: all .2s ease-in;
      }
    }

    &-leave-active {
      transition: opacity .2s ease-out, transform .2s linear;

      .is_safari & {
        transition: all .2s ease-out;
      }
    }

    &-enter-from {
      opacity: 0;
      transform: perspective(250px) rotateY(90deg);
    }

    &-enter-to {
      opacity: 1;
      transform: perspective(250px) rotateY(0deg);
    }

    &-leave-from {
      opacity: 1;
      transform: perspective(250px) rotateY(0deg);
    }

    &-leave-to {
      opacity: 0;
      transform: perspective(250px) rotateY(90deg);
    }
  }
}

.is-test-env {
  * {
    transition-duration: 0s !important;
    transition-delay: 0s !important;
    animation-duration: 0s !important;
    animation-delay: 0s !important;
  }
}

@keyframes spin {
  100% { transform: rotate(360deg); }
}

@keyframes pulse-primary {
  0% { box-shadow: 0 0 0 0 rgba($brandPrimaryColor, .5); }
  98% { box-shadow: 0 0 0 15px rgba($brandPrimaryColor, 0); }
  100% { box-shadow: none; }
}

@keyframes pulse-white {
  0% { box-shadow: 0 0 0 0 rgb(255 255 255 / .5); }
  98% { box-shadow: 0 0 0 15px rgb(255 255 255 / 0); }
  100% { box-shadow: none; }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba($brandPrimaryColor, .5);
    transform: scale(1);
  }

  25% {
    transform: scale(1.2);
  }

  50% {
    transform: scale(1);
  }

  98% {
    box-shadow: 0 0 0 15px rgba($brandPrimaryColor, 0);
  }

  100% {
    box-shadow: none;
  }
}

@keyframes pulseHeart {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  10% {
    transform: scale(1.25);
    opacity: 1;
  }

  20% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes growBounce {
  0% {
    transform: scale(.5);
    opacity: 0;
  }

  70% {
    transform: scale(1.1);
    opacity: 1;
  }

  100% {
    transform: scale(1);
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
