// Icon
$iconBurgerHeight: 3px;
$iconBurgerWidth: 24px;
$iconDuration: .3s;
$iconDelay: .3s;
$iconBurgerDuration: .3s;
$iconBurgerDelay: .3s;
$iconBurgerPseudoTransition: .3s, top .3s .3s;
$iconBurgerPseudoTransitionOpen: top .2s, transform .3s .3s;
$iconArrowLeftThin: "\e662";
$iconArrowRightThin: "\e663";
$iconColor: $blackColor;

// Header
$headerIconColor: $blackColor;
$headerSearchIconColor: $brandPrimaryColor;
$headerIconBackgroundColor: transparent;
$headerIconHoverColor: $blackColor;
$headerIconHoverBackgroundColor: #f3f3f3; //!!!!!
$loggedInIconColor: $blackColor;
$headerMenuHoverBackgroundColor: #f3f3f3;

// Footer
$footerServiceBackgroundColor: $grayLightColor;
$footerServicePadding: 24px 16px;
$footerServiceTextColor: $whiteColor;
$footerServiceDarkColor: #7b7b7b;
$footerServiceBlankColor: $textBaseColor;
$footerServiceLinkColor: $textBaseColor;
$footerServiceFontSize: $baseFontSize;
$footerServiceHeadlinePadding: 8px 0;
$footerServiceHeadlineFirstTop: 0;
$footerServiceHeadlineFontSize: 12px;
$footerServiceLineHeight: 1.8;
$footerServiceLinkLineHeight: 1.8;
$footerServiceLogoPadding: 8px 0;
$footerServiceLogoWidth: 70px;
$footerServiceLogoHeight: 70px;
$footerServiceReadMoreMargin: 20px 0 0;
$footerServiceIconFontSize: 35px;
$footerServiceSpritePadding: 20px 0 10px;
$footerServiceIconMargin: 0 6px;
$footerServiceCheckoutMargin: 16px 0 !important;
$footerServiceCheckoutPadding: 0;

// Button
$buttonBorderWidth: 1px;
$buttonBorderRadius: 0;
$buttonPrimaryHeightMobile: 42px;
$buttonPrimaryHeightDesktop: 44px;
$buttonSecondaryHeightMobile: 28px;
$buttonSecondaryHeightDesktop: 28px;
$buttonSecondaryLineHeightMobile: 28px;
$buttonSecondaryLineHeightDesktop: 28px;
$buttonFontSizeMobile: 12px;
$buttonFontSizeDesktop: 14px;
$buttonFontWeight: $fontWeightRegular;
$buttonSmallFontWeight: $fontWeightBold;
$buttonCounterWidth: 40px;
$buttonCounterHeight: 40px;
$buttonCounterWidthMobile: 40px;
$buttonCounterHeightMobile: 40px;
$buttonAddToBasketWidth: 40px;
$buttonAddToBasketHeight: 40px;
$buttonLightColor: $whiteColor;
$buttonDarkColor: $brandPrimaryColor;
$buttonBackgroundColor: $brandPrimaryColor;
$buttonBorderColor: $brandPrimaryColor;
$buttonTextColor: $whiteColor;
$buttonHoverBackgroundColor: $brandPrimaryColor;
$buttonHoverBorderColor: $brandPrimaryColor;
$buttonHoverTextColor: $whiteColor;
$buttonDisabledBackgroundColor: $grayLightColor;
$buttonDisabledBorderColor: $grayLightColor;
$buttonDisabledTextColor: $whiteColor;
$buttonShadow: 3px 3px 5px 0 rgb(0 0 0 / .5);
$buttonShadowActive: 2px 2px 3px 0 rgb(0 0 0 / .4);
$buttonPrimaryBorderColor: $moodFourColor;
$buttonPrimaryBackgroundColor: $moodFourColor;
$buttonPrimaryTextColor: $whiteColor;
$buttonPrimaryHoverBackgroundColor: darken($moodFourColor, 20%);
$buttonPrimaryHoverTextColor: $whiteColor;
$buttonSecondaryBorderColor: $moodTwoColor;
$buttonSecondaryBackgroundColor: $whiteColor;
$buttonSecondaryTextColor: $brandPrimaryColor;
$buttonSecondaryHoverBackgroundColor: $whiteColor;
$buttonSecondaryHoverTextColor: $brandPrimaryColor;
$buttonAddToBasketBackgroundColor: $brandPrimaryColor;
$buttonAddToBasketBorderColor: $brandPrimaryColor;
$buttonAddToBasketTextColor: $whiteColor;
$buttonAddToBasketHoverBackgroundColor: darken($brandPrimaryColor, 20%);
$buttonAddToBasketHoverTextColor: $whiteColor;

// consentCookieBox
$consentCookieBoxLogoWidth: 95px;
$consentCookieBoxButtonSecondaryHoverBackgroundColor: $buttonPrimaryHoverBackgroundColor;
$consentCookieBoxButtonSecondaryHoverTextColor: $buttonPrimaryHoverTextColor;
$consentCookieBoxButtonSecondaryBorderHover: 1px solid $grayMediumColor;
$consentCookieBoxButtonAcceptColor: $textLightColor;
$consentCookieBoxButtonAcceptBackgroundColor: $brandPrimaryColor;
$consentCookieBoxButtonAcceptHoverBackgroundColor: $buttonPrimaryHoverBackgroundColor;
$consentCookieBoxButtonAcceptHoverTextColor: $buttonPrimaryHoverTextColor;
$consentCookieBoxButtonAcceptBorderHover: $buttonPrimaryBorderColor;

// Price
$priceColor: $brandPrimaryColor;
$priceDiscountColor: $discountColor;

// Input
$inputFieldHeightMobile: 42px;
$inputFieldHeightDesktop: 44px;
$inputFieldFontSizeMobile: 12px;
$inputFieldFontSizeDesktop: 14px;
$inputLabelColor: inherit;
$inputLabelFontSize: 10px;
$inputShadow: 0 0 10px 0 rgb(0 0 0 / .25);
$inputTextColor: $textBaseColor;
$inputIconColor: $brandPrimaryColor;
$inputFieldColor: $textBaseColor;
$inputBorderColor: $brandPrimaryColor;
$inputFocusBorderColor: $brandPrimaryColor;
$inputSuccessBorderColor: $brandPrimaryColor;
$inputErrorBorderColor: $errorColor;
$inputBackgroundColor: $grayLighterColor;
$inputFocusBackgroundColor: $grayLighterColor;
$inputSuccessBackgroundColor: $grayLighterColor;
$inputErrorBackgroundColor: $grayLighterColor;
$inputRadioLabelFontSize: $baseFontSize;
$inputRadioLabelColor: $textMediumGrayColor;
$inputRadioLabelCheckedColor: $textBaseColor;
$inputRadioCheckedColor: $brandPrimaryColor;
$inputRadioBackgroundColor: #d8d8d8;
$inputFocusColor: $brandHighlightColor;
$inputIconValidColor: $brandHighlightColor;
$inputPlaceholderColor: $textMediumGrayColor;
$inputErrorFontSize: $fontSizeFont;
$inputGrayBackgroundColor: #f5f5f5;
$inputIconSpriteMinWidth: 50px;

// Select
$selectLabelColor: inherit;
$selectBorderRadius: 0;
$selectIconColor: $grayDarkColor;
$selectArrowFontSize: 16px;
$selectArrowFormlessFontSize: 12px;
$selectArrowFontWeight: $fontWeightBold;
$selectArrowRight: 15px;
$selectBackground: $whiteColor;
$selectTextColor: $textBaseColor;
$selectInactiveColor: $brandPrimaryColor;
$selectActiveColor: $brandPrimaryColor;
$selectFontWeightBold: $fontWeightBold;
$selectValuePadding: 13px 35px 0 11px;
$selectValueMargin: 0 0 2px;
$selectCustomOptionBackground: $inputBackgroundColor;
$selectCustomOptionSelectedColor: $selectActiveColor;
$selectCustomOptionHoverColor: inherit;
$selectCustomOptionSelectedFontWeight: $selectFontWeightBold;
$selectCustomOptionHoverBackground: darken($inputBackgroundColor, 10%);
$selectCustomOptionBorderBottom: 1px solid darken($inputBackgroundColor, 10%);
$selectCustomShadow: 0 10px 10px 0 rgb(47 47 45 / .2);
$selectCustomOptionsWrapperPadding: 8px 24px;

// Address Suggestion
$addressSuggestionColor: $inputTextColor;
$addressSuggestionBorderColor: $inputBorderColor;
$addressSuggestionBackgroundColor: $inputBackgroundColor;
$addressSuggestionSelectedFontWeight: $selectFontWeightBold;
$addressSuggestionHoverColor: $selectActiveColor;
$addressSuggestionHoverBackgroundColor: darken($inputBackgroundColor, 10%);

// passwordInput
$passwordInputIconColor: inherit;
$passwordInputTextColor: $brandPrimaryColor;

// rangeSlider
$rangeSliderFontSize: $inputFieldFontSizeMobile;
$rangeSliderHighlightColor: $brandPrimaryColor;
$rangeSliderLineColor: $grayLightColor;
$rangeSliderOuterLineColor: $grayLightColor;
$rangeSliderBorder: 2px solid $whiteColor;
$rangeSliderInputBackground: none;
$rangeSliderInputBorder: 1px solid $inputBorderColor;
$rangeSliderInputBorderActive: 1px solid $inputFocusBorderColor;
$rangeSliderInputColor: $inputFieldColor;
$rangeSliderInputBackground: $whiteColor;
$rangeSliderHandleBackground: $rangeSliderHighlightColor;
$rangeSliderHandleBorder: 2px solid $whiteColor;
$rangeSliderHandleRadius: 0;

// Carousel
$carouselButtonBackground: $brandPrimaryColor;
$carouselBorderRadius: 100%;
$carouselButtonPrimaryColor: $brandPrimaryColor;
$carouselButtonBoxShadow: rgb(0 0 0 / .2) 0 2px 2px;
$carouselButtonTextColor: $whiteColor;
$carouselButtonTextShadow: none;
$carouselButtonHoverTextColor: $whiteColor;
$carouselButtonHoverBackgroundColor: $brandPrimaryColor;
$carouselButtonFontSizeMobile: 10px;
$carouselButtonFontSizeTablet: 11px;
$carouselButtonFontSizeDesktop: 11px;
$carouselPaginationBackgroundColor: $grayColor;
$carouselPaginationHighlightColor: $brandPrimaryColor;
$carouselPaginationHoverBackground: $brandPrimaryColor;
$carouselPaginationFontSize: 13px;
$carouselButtonTextColorDesktop: $whiteColor;
$carouselButtonLeftIcon: 'arrowLeft';
$carouselButtonRightIcon: 'arrowRight';
$carouselPaginationFontSizeMobile: 16px;

// HeroSlider
$heroSliderButtonPrimaryColor: $brandPrimaryColor;
$heroSliderButtonTextColor: $whiteColor;
$heroSliderPaginationBackgroundColor: $grayLightColor;
$heroSliderPaginationHighlightColor: $brandPrimaryColor;
$heroSliderLinkButtonColor: $textBaseColor;
$heroSliderLinkButtonBackgroundColor: $whiteColor;
$heroSliderLinkButtonBorderColor: $whiteColor;
$heroSliderLinkButtonProductColor: $whiteColor;
$heroSliderLinkButtonProductBackgroundColor: $brandPrimaryColor;
$heroSliderLinkButtonProductBorderColor: $brandPrimaryColor;
$heroSliderCarouselButtonHoverBackground: $heroSliderButtonPrimaryColor;
$heroSliderCarouselButtonBoxShadow: 0 0 0 $buttonBorderWidth $heroSliderButtonTextColor;
$heroSliderPaginationBottom: -3px;
$heroSliderCarouselButtonColor: $heroSliderButtonPrimaryColor;
$heroSliderDisplayNewFlag: block;
$heroSliderHeadlineFontWeight: $fontWeightSemiBold;

// SaleBox
$saleBoxBackgroundColor: $whiteColor;
$saleBoxShadow: 0 2px 4px 0 rgb(0 0 0 / .4);
$saleBoxWishlistColor: $brandPrimaryColor;
$saleBoxBasketHeaderBackground: $brandPrimaryColor;
$saleBoxBasketHeaderColor: $whiteColor;
$saleBoxBasketHeaderIconBackground: $whiteColor;
$saleBoxBasketHeaderIconColor: $brandPrimaryColor;

// SaleboxBar
$saleBoxBarNameColor: $brandPrimaryColor;
$saleBoxBarNameFontWeight: $fontWeightBold;

// ImageTextAction Teaser
$imageTextTeaserBorderPadding: 0px; // stylelint-disable-line
$imageTextTeaserButtonColor: $whiteColor;
$imageTextTeaserButtonBackgroundColor: $brandPrimaryColor;
$imageTextTeaserButtonBorderColor: $brandPrimaryColor;
$imageTextTeaserBoxBackground: linear-gradient(180deg, rgb(255 255 255 / .75) 0%, $whiteColor 50%, $whiteColor 100%);
$imageTextTeaserBoxPosition: absolute;
$imageTextTeaserHeadlineColor: $brandPrimaryColor;
$imageTextTeaserHeadlineSize: $fontSizeXXLarge;
$imageTextTeaserBorder: 0;
$imageTextTeaserMinHeight: 180px;
$imageTextTeaserWideSublineFontSize: 15px;

// productListPage
$productListPageNameMargin: 0 0 15px 0;
$productListPageIntroFontSize: $fontSizeMedium;

// MoodTeaser
$moodTeaserTextColor: $textBaseColor;
$moodTeaserHeadlineColor: $textBaseColor;
$moodTeaserNoImgTextColor: $textBaseColor;
$moodTeaserTextShadow: 0 0 2px $whiteColor, 0 0 2px $whiteColor, 0 0 2px $whiteColor;

// ProductTeaser
$productTeaserPriceUnitPadding: 5px 0 0;

// ProductBox
$productBoxBorder: 1px solid $grayColor;
$productBoxListBorder: 1px solid $grayColor;
$productBoxWhishListBorder: 1px solid $grayColor;
$productBoxListPadding: 10px;
$productBoxBackgroundColor: $whiteColor;
$productBoxNameColor: $textGrayColor;
$productBoxShortDescriptionColor: $grayMediumColor;
$productBoxButtonWishlistColor: $brandPrimaryColor;
$productBoxWishlistColor: $brandPrimaryColor;
$productBoxLoadingColor: $brandPrimaryColor;
$productBoxStatusPadding: 16px 12px;
$productBoxStatusSuccessorBackgroundColor: $grayLightColor;
$productBoxStatusSuccessorColor: $redDarkColor;
$productBoxLinkColor: $grayColor;
$productBoxLinkFontSize: $fontSizeXXSmall;
$productBoxWideBottleBackground: radial-gradient(circle, $brandPrimaryColor 0%, #000 100%) 50% -220px / 100% 200%;
$productBoxStatusBorderRadius: 4px;
$productBoxUnavailableTextTransform: none;

// ProductBoxBasket
$productBoxBasketHeaderBackground: $grayMediumColor;
$productBoxBasketContentBackground: $grayLighterColor;

// productBoxSet
$productBoxSetHeaderFontSize: 16px;
$productBoxSetLongDescriptionFontSize: 16px;
$productBoxSetLongDescriptionLineHeight: 1.3;
$productBoxSetBorderBottom: $grayLightColor 1px solid;
$productBoxSetUnitColor: $grayColor;
$productBoxSetUnitFontSize: 10px;
$productBoxSetPriceMargin: 17px 0 0;
$productBoxSetButtonMargin: 14px 0 0;
$productBoxSetButtonFontSize: 16px;

// productBoxCampaign
$productBoxCampaignColorMix: #f86729;
$productBoxCampaignColorRedwine: #6f1c17;
$productBoxCampaignColorRosewine: #f76540;
$productBoxCampaignColorWhitewine: #385b9d;
$productBoxCampaignColorTable: #e2dbca;

// productReviews
$productReviewsBackground: $brandPrimaryColor;
$productReviewsColor: $textLightColor;
$productReviewsHeadlineFontSize: $fontSizeXXXMedium;
$productReviewsHeadlineFontWeight: $fontWeightRegular;
$productReviewsSummaryText: $fontSizeMedium;
$productReviewsButtonBackground: $buttonSecondaryBackgroundColor;
$productReviewsButtonHoverColor: $buttonSecondaryBackgroundColor;
$productReviewsButtonColor: $buttonSecondaryTextColor;
$productReviewsSummaryStarSize: 24px;
$productReviewsFormBackground: $backgroundWhiteColor;

// product-review-form
$productReviewFormSectionBackground: #f5f5f5;
$productReviewFormPadding: 10px 15px;
$productReviewFormMarginTop: 15px;

// productReviewsList
$productReviewsListHeadlineFontSize: $fontSizeXXXMedium;
$productReviewsListHeadlineFontWeight: $fontSizeMedium;
$productReviewsListTextFontSize: $baseFontSize;
$productReviewsListTextFontWeight: $baseFontSize;
$productReviewsListTextColor: $textMediumGrayColor;
$productReviewsListReadMoreFontSize: 16px;
$productReviewsListReadMoreColor: $redDarkColor;
$productReviewsListReadMoreFontWeight: $fontWeightBold;
$productReviewsListFilterRowBorderBottom: $borderDefaultColor 1px solid;
$productReviewsListFilterMarginMobile: -8px 0 0 -16px;
$productReviewsListFilterMarginDesktop: -4px 0 0 -16px;

// productReview
$productReviewBaseColor: $textBaseColor;
$productReviewBaseFontSize: $baseFontSize;
$productReviewTitleFontSize: 18px;
$productReviewTitleFontWeight: $fontWeightSemiBold;
$productReviewTitleTextColor: $grayColor;
$productReviewAuthorColor: $grayColor;
$productReviewTextFontWeight: $fontWeightRegular;
$productReviewTextColor: $textBaseColor;

// voteInput
$voteInputColor: inherit;
$voteInputBorder: $borderDefaultColor 1px solid;
$voteInputBackground: $backgroundWhiteColor;
$voteInputCheckedPositiveColor: inherit;
$voteInputCheckedPositiveBackground: transparentize($successColor, .5);
$voteInputCheckedNegativeColor: inherit;
$voteInputCheckedNegativeBackground: transparentize($textErrorColor, .5);

// ServiceTeaser
$serviceHeadlineFontSize: 16px;
$serviceSublineFontSize: 16px;
$serviceIconColor: $brandPrimaryColor;
$serviceLineColor: $grayMediumColor;
$serviceHeadlineColor: $textBaseColor;
$serviceSublineColor: $textMediumGrayColor;
$serviceTeaserBorder: $serviceLineColor 1px solid;

// SocialMedia
$socialMediaFontWeight: $fontWeightSemiBold;
$socialMediaLinkColor: $grayColor;
$socialMediaLinkHoverColor: $textBaseColor;

// Tags
$tagBorderRadius: 0;
$tagFontSize: 16px;
$tagFontWeight: $fontWeightThin;
$tagPadding: 5px 10px;
$tagPaddingMobile: 5px 10px;
$tagPaddingRight: 35px;
$tagColor: $textBaseColor;
$tagBackgroundColor: $grayLightColor;
$tagBorderColor: $grayLightColor;
$tagHoverColor: $textBaseColor;
$tagBorderHoverColor: $textBaseColor;
$tagBackgroundHoverColor: $grayLightColor;
$tagCloseColor: $textBaseColor;

// Flags
$flagTextColor: $whiteColor;
$flagUndefinedColor: $brandPrimaryColor;
$flagNewColor: $brandPrimaryColor;
$flagStoererColor: $brandPrimaryColor;
$flagBioColor: $greenColor;
$flagAwardColor: $goldColor;
$flagSavedPercentageColor: $discountColor;
$flagBoxSetUrlColor: $redColor;
$flagQuoteFontSize: 13px;
$flagQuoteFontWeight: $fontWeightRegular;
$flagQuoteColor: $textBaseColor;
$flagAuthorFontSize: 12px;
$flagAuthorFontWeight: $fontWeightRegular;
$flagAuthorColor: $textBaseColor;

// flagSaving
$flagSavingFirstColor: #f86729;
$flagSavingSecondColor: #faf7f1;

// origin-highlights
$originHighlightsMargin: -4px 8px 0 0;
$originHighlightsBackground: $buttonBackgroundColor;
$originHighlightsPadding: 5px 10px;
$originHighlightsColor: $buttonTextColor;
$originHighlightsBorderRadius: $buttonBorderRadius;
$originHighlightsBorder: 0 none;

// Rating
$ratingStarsColor: $goldColor;
$ratingEmptyStarsColor: $whiteColor;
$ratingStarsHoverColor: lighten($goldColor, 20%);

// Pagination
$paginationTextColor: $textBaseColor;
$paginationButtonTextColor: $brandPrimaryColor;
$paginationButtonBorderColor: $inputBorderColor;
$paginationSelectTextColor: $textBaseColor;
$paginationSelectBackgroundColor: $whiteColor;
$paginationSelectBorderColor: $brandPrimaryColor;
$paginationSelectColor: $blackColor;
$paginationSelectArrowColor: $redDarkColor;
$paginationPagesCountColor: $textBaseColor;
$paginationLabelColor: $textBaseColor;
$paginationButtonsJustifyContent: flex-end;
$paginationPagesFlexGrow: inherit;
$paginationSelectMargin: 0;
$paginationButtonMarginTop: 0;
$paginationSelectFontSize: 18px;
$paginationButtonFontSize: 10px;
$paginationSelectArrowFontSize: 10px;
$paginationLabelFontSize: 14px;
$paginationBaseFontSize: 14px;
$paginationSelectFontWeight: $selectFontWeightBold;
$paginationButtonPadding: 18px 19px;
$paginationButtonRightMargin: -19px;

// siblingNavigation
$siblingNavigationBackground: linear-gradient(90deg, rgb(255 255 255 / 0) 0%, $whiteColor 100%);
$siblingNavigationColor: $grayMediumColor;
$siblingNavigationActiveColor: $brandSecondaryColor;
$siblingNavigationActiveFontWeight: inherit;
$siblingNavigationActiveBorderBottom: 2px $brandSecondaryColor solid;
$siblingNavigationCountColor: $grayColor;
$siblingNavigationItemFontSize: 14px;

// Datepicker
$datePickerHeaderBackgroundColor: $brandPrimaryColor;
$datePickerHeaderTextColor: $whiteColor;
$datePickerDayBackgroundColor: $grayLighterColor;
$datePickerTextColor: $textBaseColor;
$datePickerHoverColor: $brandPrimaryColor;
$datePickerHoverTextColor: $whiteColor;
$datePickerCheckedColor: $brandPrimaryColor;
$datePickerIconColor: inherit;
$datePickerSelectColor: $textBaseColor;
$datePickerArrowDisabledColor: $textMediumGrayColor;
$datePickerFontSize: $baseFontSize;
$datePickerWidth: 270px;

// goTop
$goTopWidthMobile: 30px;
$goTopWidthDesktop: 50px;
$goTopColor: $brandPrimaryColor;
$goTopBackgroundColor: $whiteColor;
$goTopBoxShadow: 0 0 0 1px #243747;
$goTopHoverColor: $whiteColor;
$goTopHoverBackgroundColor: $brandPrimaryColor;
$goTopFontSizeMobile: 10px;
$goTopFontSizeDesktop: 17px;
$goTopIcon: arrowUp;
$goTopIconTextShadow: none;
$goTopIconRightMobile: 5px;

// Loader
$loaderColor: $brandPrimaryColor;
$loaderBackgroundColor: rgb(255 255 255 / .5);

// ScrollToContentButton
$scrollToContentButtonColor: $brandPrimaryColor;
$scrollToContentButtonTransform: uppercase;
$scrollToContentButtonDecoration: none;
$scrollToContentButtonLetterSpacing: 1.86px;

// scrollAnimatedTeaser
$scrollAnimatedTeaserBorderRadius: 0;

// searchAutosuggest
$searchAutosuggestBackgroundColor: $grayLighterColor;
$searchAutosuggestHoverColor: $textHoverColor;
$searchAutosuggestHoverBorderColor: $brandPrimaryColor;
$searchAutosuggestSuggestionFontSize: $baseFontSize;
$searchAutosuggestSuggestionFontSizeMobile: $fontSizeMedium;
$searchAutosuggestSuggestionLineHeight: 1;
$searchAutosuggestSuggestionLineHeightMobile: 1.1;
$searchAutosuggestSuggestionFontWeight: $fontWeightRegular;
$searchAutosuggestSuggestionColor: $textMediumGrayColor;
$searchAutosuggestHeadlineFontSize: $fontSizeSmall;
$searchAutosuggestHeadlineColor: $textMediumGrayColor;
$searchAutosuggestListLeftWidth: 30%;

// Filters
/* stylelint-disable plugin/no-unsupported-browser-features */
$filtersColor: $brandPrimaryColor;
$filtersMobileBackground: $backgroundWhiteColor;
$filtersHeadlineMobileColor: $brandPrimaryColor;
$filtersFontSize: $baseFontSize;
$filtersFontWeight: $fontWeightRegular;
$filtersTextTransform: uppercase;
$filtersLetterSpacing: 1px;
$filtersIconColor: $brandPrimaryColor;
$filtersBorderColor: $borderDefaultColor;
$filtersFilterBorderTop: 1px solid $borderDefaultColor;
$filtersProductsSortBorder: 1px solid $grayDarkColor;
$filtersProductsSortTextTransform: uppercase;
$filtersArrowRightMobile: 15px;
$filtersArrowRightDesktop: 5px;
$filtersArrowFontSize: 14px;
$filtersArrowFontWeight: $fontWeightBold;
$filtersInputFocusBorderColor: $inputFocusBorderColor;
$filtersLoaderBackground: rgb(255 255 255 / .1);
$filtersLoaderColor: $brandPrimaryColor;
$filtersLoaderOpacity: .5;
$filtersPaddingSpacing: 15px;
$filtersListBackground: transparent;
$filtersWrapperBg: $backgroundWhiteColor;
/* stylelint-enable */

// Filter
/* stylelint-disable plugin/no-unsupported-browser-features */
$filterColor: $textBaseColor;
$filterBackgroundColor: $whiteColor;
$filterCountColor: $textMediumGrayColor;
$filterCheckboxColor: $grayLightColor;
$filterCheckboxActiveColor: $brandPrimaryColor;
$filterLabelFontSize: 14px;
$filterBoxBorder: 2px solid $filterCheckboxColor;
$filterSwitchColor: $grayLightColor;
$filterSwitchBackgroundColor: $whiteColor;
$filterSwitchActiveColor: $successColor;
$filterSwitchHeight: 20px;
$filterSwitchWidth: 44px;
$filterSwitchShadow: inset 0 0 2px 1px rgb(0 0 0 / .2);
$filterSwitchToggleShadow: 0 0 2px 1px rgb(0 0 0 / .3);
/* stylelint-enable */

// FilterPrice
/* stylelint-disable plugin/no-unsupported-browser-features */
$filterPriceUnitColor: $grayColor;
/* stylelint-enable */

// sidebarMenu
$sidebarMenuColor: $textBaseColor;
$sidebarMenuLinkColor: $textBaseColor;
$sidebarMenuSelectedColor: $brandPrimaryColor !important;
$sidebarMenuSelectedFontWeight: $fontWeightRegular;
$sidebarMenuFontSize: $fontSizeMedium;
$sidebarMenuFontWeight: $fontWeightRegular;
$sidebarMenuBorderBottom: $borderDefaultColor 1px solid;

// Grid Backgrounds
$gridBackgroundColor1: $whiteColor;
$gridBackgroundColor2: $grayLighterColor;
$gridBackgroundColor3: $grayLightColor;
$gridBackgroundColor4: $grayMediumColor;
$gridBackgroundColor5: $grayDarkColor;
$gridElementTeaserBackground: $whiteColor;
$gridElementTeaserTextColor: #000;

// Winefinder
$winefinderButton: $grayLightColor;

// FloatingToolbar
$floatingToolbarBgColor: $brandPrimaryColor;
$floatingToolbarTextColor: $whiteColor;

// ProductAwards
$productAwardTileBackground: #f3f3f3;
$productAwardTileBoxShadow: none;
$productAwardTileHeadlineColor: $textBaseColor;
$productAwardTileTextColor: $grayDarkColor;
$productAwardTileLinkColor: $grayDarkColor;
$productAwardItemBackground: #f3f3f3;
$productAwardItemBoxShadow: none;
$productAwardBorderColor: $grayColor;
$productAwardTileMinHeight: 325px;
$productAwardFilterSortBorder: $borderDefaultColor 1px solid;
$productAwardArrowBottomWidth: 20px;
$productAwardArrowBottomColor: $productAwardBorderColor;
$productAwardsItemImageWidth: auto;
$productAwardsItemImageMaxWidth: none;
$productAwardsCloseColor: $whiteColor;

// checkbox
$checkboxWidth: 22px;
$checkboxBorderRadius: 0;
$checkboxLabelPaddingLeftMobile: 24px;
$checkboxLabelPaddingLeftDesktop: 29px;
$checkboxLabelFontSizeMobile: 12px;
$checkboxLabelFontSizeDesktop: 14px;
$checkboxLabelLineHeight: 1.3;
$checkboxBackground: $grayLighterColor;
$checkboxLabelColor: $textGrayColor;
$checkboxCheckedColor: $brandPrimaryColor;
$checkboxCheckedBorderColor: $brandPrimaryColor;
$checkboxBoxTop: 50%;
$checkboxBoxTransform: translateY(-50%);
$checkboxSwitchWrapperMargin: -1px 0 -4px;
$checkboxSwitchMargin: 3px 0 3px 1px;
$checkboxSwitchLabelMargin: 1px 12px 3px 12px;

// giftGreetingText
$giftGreetingTextBorderRadius: 4px;
$giftGreetingTextBorder: 1px solid $grayLightColor;
$giftGreetingTextCheckboxColor: $redDarkColor;
$giftGreetingTextCheckboxCheckedColor: black;

// product-order-free-item
$productOrderFreeItemBackgroundColorLegacy: $grayLighterColor;
$productOrderFreeItemBackgroundColor: $grayLighterColor;
$productOrderFreeItemHeadlineColor: $redDarkColor;
$productOrderFreeItemNameColor: $redDarkColor;
$productOrderFreeItemShortDescriptionColor: $grayColor;
$productOrderFreeItemButtonSelectedColor: $whiteColor;
$productOrderFreeItemButtonSelectedBackgroundColor: $successColor;
$productOrderFreeItemHeadlineSmallSize: 18px;
$productOrderFreeItemHeadlineSmallWeight: $fontWeightRegular;
$productOrderFreeItemDescription3Color: $textGrayColor;
$productOrderFreeItemDescription3Display: none;
$productOrderFreeItemButtonColor: $whiteColor;
$productOrderFreeItemButtonBorderColor: #ccc;

// productOrderTable
$productOrderTableErrorBackgroundColor: #f2e5e5;
$productOrderTableErrorBorderColor: $errorColor;
$productOrderTableErrorTextColor: $errorColor;

// scollbars
$customScrollbarTrackColor: $whiteColor;
$customScrollbarThumbColor: $brandPrimaryColor;
$customScrollbarThumbHoverColor: $buttonPrimaryHoverBackgroundColor;

// addToCart
$addToCartPrimaryColor: $brandPrimaryColor;
$addToCartHeadlineTextTransform: uppercase;
$addToCartHeadlineLetterSpacing: 1px;

// productOrderPosition
$productOrderPositionRemoveIcon: 'delete';
$productOrderPositionRemovePadding: 12px 20px 10px;
$productOrderPositionRemoveColor: $brandPrimaryColor;
$productOrderPositionNameColor: $brandPrimaryColor;
$productOrderPositionProductInfoColor: $textBaseColor;
$productOrderPositionProductInfoFontSize: 13px;
$productOrderPositionProductInfoPadding: 9px 0 0;
$productOrderPositionProductInfoFontWeight: $fontWeightSemiBold;
$productOrderPositionPriceFontSize: 10px;
$productOrderPositionPriceInfoCurrentFontSize: 18px;
$productOrderPositionPriceInfoCurrentMobileFontSize: 14px;
$productOrderPositionPriceInfoOldValueFontSize: 10px;
$productOrderPositionPriceInfoOldValueMobileFontSize: 10px;
$productOrderPositionPromotionFontSize: 12px;
$productOrderPositionPromotionBg: $redDarkColor;
$productOrderPositionPromotionText: $whiteColor;
$orderPositionAboColor: $brandPrimaryColor;
$orderPositionNameFontWeight: $fontWeightRegular;

// availabilityInfo
$availabilityInfoFontMobile: 10px;
$availabilityInfoFontTablet: 12px;
$availabilityInfoFontDesktop: 12px;
$availabilityInfoDotMobile: 6px;
$availabilityInfoDotDesktop: 8px;
$availabilityInfoLabelMargin: 0 0 2px 14px;
$availabilityInfoSpaceMinusLeft: -16px;
$availabilityInfoBold: 500;
$availabilityInfoTextTransform: uppercase;

// singleOrderDetails
$singleOrderDetailsFontSizeSmall: 12px;
$singleOrderDetailsFontSizeBase: 14px;
$singleOrderDetailsFontSizeMedium: 16px;
$singleOrderDetailsFontSizeBig: 18px;
$singleOrderDetailsFontSizeBiggest: 30px;
$singleOrderDetailsShipmentHeaderBorder: $borderDefaultColor 2px solid;
$singleOrderTableHeaderBorder: 1px solid $grayLightColor;
$singleOrderTableHeaderPaddingInUserArea: 15px 0 0;
$singleOrderTableHeaderBorderInUserArea: 2px solid $grayLightColor;
$singleOrderTableProductBorderBottom: $borderDefaultColor 1px solid;
$singleOrderTableHeaderFontWeight: $fontWeightBold;
$singleOrderTableHeaderColor: $textBaseColor;
$singleOrderDetailsSectionNameColor: $brandSecondaryColor;
$singleOrderDetailsSectionNameFontWeight: $fontWeightSemiBold;
$singleOrderDetailsStatusColor: $brandPrimaryColor;
$singleOrderDetailsStatusFontSize: $fontSizeXSmall;
$singleOrderDetailsOrderHeaderBorderTop: 1px solid $blackColor;
$singleOrderDetailsTrackingMaxWidth: 210px;
$singleOrderDetailsHeadAlign: left;
$singleOrderDetailsSummaryTextColor: $grayColor;

// $singleOrderCancel
$singleOrderCancelColor: $brandPrimaryColor;

// singleOrderDetailsInvoice
$singleOrderDetailsInvoiceBackground: #f8f8f8;

// accountOrdersList
$accountOrdersListHeadlineMargin: 40px 0 15px;
$accountOrderListPagingMargin: 0 0 -24px;

// accountOrdersListView
$accountOrdersListViewBorderColor: $borderDefaultColor;
$accountOrdersListViewBorderWidth: 2px;
$accountOrdersListViewPadding: 0 0 10px 0;
$accountOrdersListViewAccordionPadding: 0 0 40px;
$accountOrdersListViewContentColor: $whiteColor;
$accountOrdersListViewContentPadding: 0;
$accountOrdersListViewContentAvailabilityPadding: 14px;
$accountOrdersListViewContentBorder: 0 none;
$accountOrdersListViewOrderBorderBottom: 1px solid $textDarkestColor;

// accountSingleOrder
$accountSingleOrderTextTransform: uppercase;
$accountSingleOrderTableHeadlineJustify: flex-start;
$accountSingleOrderTableTextMargin: 0 12px 0 0;

// accordion
$accordionContentBorderBottomInUserArea: 1px solid $grayColor;
$accordionContentArrowColor: $brandPrimaryColor;

// accordionList
$accordionListIconColor: $redDarkColor;
$accordionListBorderColor: $redDarkColor;
$accordionListBorderHoverColor: $redDarkColor;
$accordionListHeadlineBackgroundColor: $whiteColor;
$accordionListHeadlineColor: $redDarkColor;
$accordionListContentBackgroundColor: $whiteColor;
$accordionListHeadlineHoverColor: $whiteColor;
$accordionListHeadlineHoverBackgroundColor: $redDarkColor;
$accordionListIconWidth: 32px;
$accordionListContentHeadlineMobilePadding: 16px 52px 16px 16px;
$accordionListContentHeadlineDesktopPadding: 16px 52px 16px 16px;
$accordionListContentInnerMobilePadding: 10px;
$accordionListContentInnerDesktopPadding: 20px;

// creditcard
$creditcardInfotextDisplay: inline-block;

// CounterInput
$buttonCounterBorderRadius: 0;
$buttonCounterHideButtonPosition: 0;
$buttonCounterShowButtonPosition: -22px;
$buttonCounterLineHeightIncreaseButton: 30px;
$buttonCounterLineHeightDecreaseButton: 30px;
$buttonCounterBackgroundColor: $whiteColor;
$buttonCounterBorderColor: $brandPrimaryColor;
$buttonCounterTextColor: $textBaseColor;
$buttonCounterHoverBorderColor: darken($brandPrimaryColor, 20%);
$buttonCounterDisabledTextColor: $textBaseColor;
$buttonCounterDisabledBorderColor: $grayLighterColor;
$counterInputFontWeight: $fontWeightSemiBold;

// OrderSummary
$orderSummaryFontWeightBold: $fontWeightSemiBold;

// VoucherForm
$voucherFormFontWeightBold: $fontWeightSemiBold;
$voucherFormInfoFontWeight: $fontWeightRegular;
$voucherFormInfoColor: $grayColor;
$voucherFormInputBackground: $inputBackgroundColor;

// CartPage
$cartPageHeaderMarginMobile: 16px 0;
$cartPageHeaderMarginTablet: 40px auto 25px;
$cartPageHeaderMarginDesktop: 40px auto 25px;
$cartPageCurrencyFontSizeProduct: 1em;
$cartPageCurrencyFontSizeTotal: 1em;
$cartPageEmptyTextColor: $brandSecondaryColor;
$cartPageEmptyFontWeight: $fontWeightSemiBold;

// form Headline
$formHeadlineFontSizeMobile: 25px;
$formHeadlineFontSizeDesktop: 28px;
$formHeadlineMarginMobile: 0 0 10px;
$formHeadlineMarginDesktop: 0;
$formHeadlineFontWheight: $fontWeightSemiBold;
$formHeadlineColor: $textBaseColor;

// checkoutStepDelivery
$checkoutStepDeliverySubHeadlineFontSize: $fontSizeMedium;
$checkoutStepDeliverySubHeadlineFontWeight: $fontWeightSemiBold;
$checkoutStepDeliverySublineFontSize: $baseFontSize;
$checkoutStepDeliverySublineFontWeight: $fontWeightRegular;

// $checkoutElement
$checkoutElementBoxBackground: $whiteColor;
$checkoutElementBoxLeftSideBackground: $whiteColor;

// ProductsOrderTable
$productsOrderTableHeaderPadding: 42px 0 0;
$productsOrderTableHeaderLabelFontWeight: $fontWeightSemiBold;
$productsOrderTableCellLabelTextTransform: uppercase;
$productsOrderTableSplitMessageBackground: $brandTertiaryColor;
$productsOrderTableBackgroundColor: $whiteColor;
$productOrderTableErrorBackgroundColor: #f2e5e5;
$productOrderTableErrorBorderColor: $errorColor;
$productOrderTableErrorTextColor: $errorColor;

// Breadcrumbs
$breadcrumbsTextColor: $textMediumGrayColor;
$breadcrumbsSeparatorColor: $textMediumGrayColor;
$breadcrumbsLastItemFontWeigth: $fontWeightBold;
$breadcrumbsMargin: 24px auto 20px;
$breadcrumbsLinkFontSize: $fontSizeSmall;
$breadcrumbsLinkAfterMarginTop: -5px;

// Sort
$productsSortSelectMargin: 0 0 0 10px;
$productsSortLabelColor: $textBaseColor;
$productsSortLabelFontSize: 18px;
$productsSortSelectColor: $brandPrimaryColor;
$productsSortSelectFontWeight: $fontWeightBold;
$productsSortSelectArrowColor: $brandPrimaryColor;
$productsSortSelectArrowFontSize: inherit;

// addToCartLayer
$addToCartLayerTopTablet: -5px;
$addToCartLayerTopDesktop: -60px;
$addToCartLayerRightTablet: 31px;
$addToCartLayerRightDesktop: 31px;

// loginForm
$loginFormLinkMargin: 15px 0;

// treeNavigation
$treeNavigationMargin: 0;
$treeNavigationBorderLeft: $grayLightColor 1px solid;
$treeNavigationPaddingLeft: 16px;
$treeNavigationTextColor: $grayDarkColor;
$treeNavigationHighlightColor: $brandPrimaryColor;
$treeNavigationFontSize: 16px;
$treeNavigationFontSizeSecondary: 14px;
$treeNavigationFontSizeChildren: 10px;
$treeNavigationFontWeight: $fontWeightBold;
$treeNavigationFontWeightActive: $fontWeightBold;
$treeNavigationItemPadding: 4px 0;
$treeNavigationItemPaddingMobile: 5px 0;
$treeNavigationL1ItemHeight: 37px;
$treeNavigationL2ItemHeight: 37px;
$treeNavigationL3ItemHeight: 33px;

// DropdownNavigation
$dropdownNavigationMargin: 0;
$dropdownNavigationBackground: $whiteColor;
$dropdownNavigationBorder: $grayLightColor 1px solid;
$dropdownNavigationBorderBottom: $grayLightColor 1px solid;
$dropdownNavigationItemSize: $fontSizeSmall;
$dropdownNavigationNameColor: $brandPrimaryColor;
$dropdownNavigationNameFontWeight: $fontWeightSemiBold;
$dropdownNavigationNameFontSize: $baseFontSize;
$dropdownNavigationBorderRadius: 4px;
$dropdownNavigationInputBackground: $grayLighterColor;

// productsListPage
$productsListPageNavigationPadding: 0 0 20px;
$productsListPageFiltersPadding: 0;
$productsListInsertionTextColor: $brandPrimaryColor;
$productsListInsertionHeadlineFontWeight: $fontWeightRegular;
$productsListInsertionHeadlineMargin: 0;
$productsListInspirationTabAnchorTopM: -100px;
$productsListInspirationTabAnchorTopD: -100px;

// productsList
$productsListSpaceMobile: 10px;
$productsListSpaceTablet: 12px;
$productsListSpaceDesktop: 24px;

// productContentBlock
$productContentBlockColor: $textBaseColor;

// accountWelcomeView
$accountWelcomeViewBoxBorder: #aaa 1px solid;

// userArea
$userAreaBoxContainerBorderMobile: none;
$userAreaBoxContainerBorderDesktop: 1px solid $borderDarkColor;
$userAreaBoxHeadlineColor: $brandPrimaryColor;
$userAreaBoxHeadlineFontSize: $fontSizeXXXMedium;
$userAreaBoxHeadlineFontWeight: $fontWeightSemiBold;
$userAreaTextSecondaryColor: $textMediumGrayColor;
$accountNewsletterViewSubheadlineFontWeight: $fontWeightSemiBold;
$accountNewsletterViewSubheadlineEMailAddressFontWeight: $fontWeightSemiBold;
$accountNewsletterViewInfoTextPaddingBottom: 12px;
$accountNewsletterFormDescrFontSize: 1em;

// headerCheckout
$headerCheckoutBoxShadow: 0 0 5px 1px rgb(0 0 0 / .5);
$headerCheckoutBackground: $backgroundWhiteColor;
$headerCheckoutMobileMinHeight: 65px;
$headerCheckoutDesktopMinHeight: 65px;
$headerCheckoutMobileWidth: 90px;
$headerCheckoutDesktopWidth: 110px;
$headerCheckoutPadding: 10px;
$headerCheckoutSvgMobileHeight: 55px;
$headerCheckoutSvgDesktopHeight: 55px;
$headerCheckoutSvgMobileWidth: 55px;
$headerCheckoutSvgDesktopWidth: 55px;

// checkoutSteps
$checkoutStepsMobileWidthLegacy: 40px;
$checkoutStepsDesktopWidthLegacy: 50px;
$checkoutStepsMobileFontSizeLegacy: 25px;
$checkoutStepsDesktopFontSizeLegacy: 30px;
$checkoutStepsTrackBorderTopLegacy: $brandPrimaryColor 1px solid;
$checkoutStepsBorderLegacy: $grayDarkColor 1px solid;
$checkoutStepsBackgroundLegacy: $whiteColor;
$checkoutStepsColorLegacy: $brandPrimaryColor;
$checkoutStepsLabelFontSizeLegacy: 16px;
$checkoutStepsLabelColorLegacy: $blackColor;
$checkoutStepsLabelFontWeightLegacy: $fontWeightRegular;
$checkoutStepsLabelTextTransformLegacy: inherit;
$checkoutStepsActiveColorLegacy: $brandPrimaryColor;
$checkoutStepsActiveBorderLegacy: $grayDarkColor 1px solid;

$checkoutStepsColor: $grayColor;
$checkoutStepsActiveColor: $brandPrimaryColor;
$checkoutStepsTextColor: $whiteColor;
$checkoutStepsTextLabelFontSizeMobile: 12px;
$checkoutStepsTextLabelFontSizeDesktop: 12px;
$checkoutStepsTextLabelFontWeight: $fontWeightBold;
$checkoutStepsMobileWidth: 26px;
$checkoutStepsDesktopWidth: 36px;
$checkoutStepsMobileLineHeight: 26px;
$checkoutStepsDesktopLineHeight: 36px;
$checkoutStepsMobileFontSize: 13px;
$checkoutStepsDesktopFontSize: 13px;
$checkoutStepsFontWeight: $fontWeightBold;
$checkoutStepsLineMobileWidth: 120px;
$checkoutStepsLineDesktopWidth: 146px;
$checkoutStepsMobileTransform: translate(-122px, -34px);
$checkoutStepsDesktoptransform: translate(-151px, -43px);
$checkoutStepsMobileMaxWidth: 290px;
$checkoutStepsDesktopMaxWidth: 390px;
$checkoutStepsStepMinWidth: 65px;
$checkoutStepsMobileMargin: 16px 10px 32px;
$checkoutStepsDesktopMargin: 24px 32px 40px;

// paymentMethodForm
$paymentMethodFormLabelFontWeight: $fontWeightBold;
$paymentMethodFormLabelMargin: 0 0 16px 34px;
$paymentMethodFormLabelCheckedColor: $brandSecondaryColor;
$paymentMethodFormEditTextTransform: lowercase;
$paymentMethodFormEditFontSize: $fontSizeXXSmall;
$paymentMethodCCOptionFontSize: $fontSizeXSmall;
$paymentMethodFormIframeBackground: $inputBackgroundColor;

// deliveryOptionForm
$deliveryOptionFormPriceDisplay: block;
$deliveryOptionFormLabelFontWeight: $fontWeightBold;
$deliveryOptionFormLabelCheckedColor: $brandSecondaryColor;
$deliveryOptionFormForecastTextFontSizeMobile: 10px;
$deliveryOptionFormForecastTextFontSizeDesktop: 14px;

// checkout
$checkoutFontWeight: $fontWeightSemiBold;
$checkoutBoxBorderBottom: 1px solid $borderDefaultColor;
$checkoutBoxHeaderFontSize: $fontSizeXMedium;
$checkoutBoxContentFontSize: 14px;
$checkoutBoxButtonColor: $brandPrimaryColor;
$checkoutBoxButtonFontSize: 14px;
$checkoutBoxButtonFontWeight: $fontWeightSemiBold;
$checkoutBoxFontSize: $fontSizeSmall;
$checkoutTextFontSize: $fontSizeFont;
$checkoutSummaryLeftFontSize: $fontSizeFont;
$checkoutSummaryRightFontSize: $fontSizeFont;
$checkoutSummarySmallFontSize: 10px;
$checkoutStepSummaryHeadlineFontSizeMobile: 25px;
$checkoutStepSummaryHeadlineMarginMobile: 9px 0 16px;
$checkoutStepSummaryHeadlineFontSizeDesktop: 28px;
$checkoutStepSummaryHeadlineMarginDesktop: 10px 0 0;
$checkoutBackground: $whiteColor;
$checkoutBorderDark: $grayDarkColor;
$checkoutBoxHtmlFontSize: inherit;
$checkoutBoxHeaderFontWeight: $fontWeightSemiBold;
$checkoutBoxHeaderSidebarFontWeight: $fontWeightSemiBold;
$checkoutStepSummaryFontSizeMobile: 16px;
$checkoutStepSummaryFontSizeDesktop: 17px;
$checkoutStepSummaryBoxActionPaddingRight: 100px;
$checkoutStepSummaryTermsFontSize: $fontSizeXXSmall;

// orderSummarySmall
$orderSummarySmallFontSize: 14px;
$orderSummarySmallFontWeight: $fontWeightBold;
$orderSummarySmallSmallTextFontWeight: $fontWeightRegular;
$orderSummarySmallSmallTextFontSize: 10px;
$orderSummaryTotalFontSize: $fontSizeXMedium;
$orderSummarySmallTotalFontWeight: $fontWeightBold;

// deliveryForecastMessage
$deliveryForecastMessageFontSizeMobile: 10px;
$deliveryForecastMessageFontSizeDesktop: 14px;
$deliveryForecastMessageDeliveryOptionFontWeight: $fontWeightSemiBold;

// checkoutPage
$checkoutPageBackground: $whiteColor;
$checkoutPageColumnLastChildMobileDisplay: block;
$checkoutPageColumnLastChildDesktopDisplay: block;
$checkoutPageBorder: $grayLightColor 1px solid;
$checkoutPagePaddingM: 24px 0;
$checkoutPagePaddingD: 40px 0;
$checkoutPagePreloaderBorderRadius: 4px;

// checkoutStepSummary
$checkoutStepSummaryButtonColor: $brandPrimaryColor;
$checkoutStepSummaryButtonFontSize: 14px;
$checkoutStepSummaryButtonFontWeight: $fontWeightBold;
$checkoutStepSummaryBoxHeaderFontSizeMobile: 14px;
$checkoutStepSummaryBoxHeaderFontSizeDesktop: 18px;
$checkoutStepSummaryBoxContentFontSizeMobile: 12px;
$checkoutStepSummaryBoxContentFontSizeDesktop: 14px;
$checkoutStepSummaryBoxTextFontSizeMobile: 12px;
$checkoutStepSummaryBoxTextFontSizeDesktop: 14px;
$checkoutStepSummaryBoxTextFontWeight: $fontWeightBold;
$checkoutStepSummaryTextLinkColor: $redDarkColor;
$checkoutStepSummaryCheckboxMaxWidth: 520px;
$checkoutStepSummaryCheckboxPadding: 0 0 0 2px;
$checkoutStepSummaryCheckboxLabelColor: $grayDarkColor;
$checkoutStepSummaryCheckboxLabelLineHeight: 1.4;
$checkoutStepSummaryCheckboxLabelFontSizeMobile: 14px;
$checkoutStepSummaryCheckboxLabelFontSizeDesktop: 18px;
$checkoutStepSummaryCheckboxDeclarationFontSize: 10px;
$checkoutStepSummaryFooterTextColor: $grayDarkColor;
$checkoutStepSummaryFooterTextFontSize: 14px;
$checkoutStepSummaryShippingInfoDisplay: none;
$checkoutStepSummarySwitchPadding: 8px 0 0 59px;
$checkoutStepSummaryPaymentMethodNameFontWeight: $fontWeightSemiBold;

// productDetailedPage
$productDetailedPageBackgroundS: linear-gradient(
  lighten($backgroundGrayColor, 5%) 0%,
  $backgroundGrayColor 320px,
  $backgroundWhiteColor 320px
);
$productDetailedPageBackgroundM: linear-gradient(
  lighten($backgroundGrayColor, 5%) 0%,
  $backgroundGrayColor 380px,
  $backgroundWhiteColor 380px
);
$productDetailedPageBackgroundL: $productDetailedPageBackgroundM;
$productDetailedPageZoomIconSize: 40px;
$productDetailedPageBottomBackground1: transparent;
$productDetailedPageBottomBackground2: transparent;
$productDetailedPageBottomBackgroundFirst: trasparent;
$productDetailedPageBottomColor: inherit;
$productDetailedPageBottomHeadlineFontSize: 28px;
$productDetailedPageBottomHeadlineFontWeight: $fontWeightRegular;
$productDetailedPageHeadlineFontWeight: $fontWeightRegular;
$productDetailedPageInfoLinkFontSize: $fontSizeSmall;

// productVariationInput
$productVariationInputOptionsBoxShadow: 0 2px 4px 0 rgb(0 0 0 / .5);
$productVariationInputSelectBackground: $whiteColor;
$productVariationInputValueFontWeight: $fontWeightSemiBold;
$productVariationInputSelectedValueFontWeight: $fontWeightSemiBold;
$productVariationInputSelectPadding: 8px 40px 5px 20px;
$productVariationInputHeadRowColor: $grayColor;
$productVariationInputHeadRowFontSize: 12px;
$productVariationInputValueRowFontSize: $fontSizeMedium;
$productVariationInputValueRowColor: $textBaseColor;
$productVariationInputValueRowPadding: 0;
$productVariationInputSelectIconColor: $brandPrimaryColor;
$productVariationInputOptionsBackground: $backgroundWhiteColor;
$productVariationInputOptionsValueRowPadding: 8px 40px 8px 20px;
$productVariationInputOptionsValueRowBorderTop: $grayColor 1px solid;
$productVariationInputAvailabilityMargin: 0 auto;

// productNavigation
$productNavigationTop: 77px;
$productNavigationLinkColor: $textBaseColor;
$productNavigationLinkActiveColor: $brandPrimaryColor;
$productNavigationLinkBorderWidth: 2px;
$productNavigationLinkFontWeight: $fontWeightBold;
$productNavigationRowBackground: $grayColor;
$productNavigationSaleRowBackground: $grayLighterColor;

// headline
$headlineH1FontSize: $fontSizeXXXLarge;
$headlineH1FontWeight: $fontWeightSemiBold;
$headlineH2FontSize: $fontSizeXXLarge;
$headlineH2FontWeight: $fontWeightRegular;
$headlineH3FontSize: $fontSizeXXXMedium;
$headlineH3FontWeight: $fontWeightRegular;
$headlineH4FontSize: $fontSizeMedium;
$headlineH4FontWeight: $fontWeightRegular;
$headlineH1TextTransform: none;
$headlineH2TextTransform: none;
$headlineH3TextTransform: uppercase;
$headlineH4TextTransform: none;
$headlineTopLineFontSize: 26px;
$headlineTopLineFontWeight: $fontWeightRegular;
$headlineTopLineLetterSpacing: 4px;
$headlineTopLineMargin: 0 0 14px;
$headlineMainLineFontSize: 40px;
$headlineMainLineFontWeight: $fontWeightBold;
$headlineSubLineFontSize: 26px;
$headlineSubLineFontWeight: $fontWeightRegular;
$headlineSubLineMargin: 25px 0 10px;
$headlineSubLineMaxWidth: 550px;

// htmlBlock
$htmlBlockTextFontSize: $baseFontSize;
$htmlBlockTextFontSizeMedium: $fontSizeXMedium;
$htmlBlockTextFontWeight: $fontWeightBold;
$htmlBlockTextLineHeight: 1.4;
$htmlBlockTextColor: $textBaseColor;
$htmlBlockTableColor: $grayColor;

// teaserHeadline
$teaserHeadlineFontSize: $fontSizeXXXLarge;
$teaserHeadlineFontWeight: $fontWeightRegular;
$teaserHeadlineColor: $brandSecondaryColor;

// ContentStarter Teaser
$contentStarterBoxBackgroundColorMobile: linear-gradient(180deg, rgb(255 255 255 / .75) 0%, #fff 20px, #fff 100%);
$contentStarterBoxBackgroundColorTablet: linear-gradient(180deg, rgb(255 255 255 / .75) 0%, #fff 30px, #fff 100%);
$contentStarterBoxBackgroundColorDesktop: linear-gradient(180deg, rgb(255 255 255 / .75) 0%, #fff 60px, #fff 100%);
$contentStarterBackgroundColor: $backgroundWhiteColor;
$contentStarterHeaderColor: $textBaseColor;
$contentStarterHeaderFontSizeMobile: $fontSizeXYMedium;
$contentStarterHeaderFontWeight: $fontWeightBold;
$contentStarterHeaderFontSizeDesktop: $fontSizeXXXLarge;
$contentStarterSubTitleColor: $grayColor;
$contentStarterSubTitleFontSize: $fontSizeXMedium;
$contentStarterSubTitleFontWeight: $fontWeightRegular;
$contentStarterPublishedFontSize: $fontSizeXSmall;
$contentStarterPublishedColor: $grayColor;
$contentStarterSeparatorDisplay: block;
$contentStarterSeparatorColor: $brandHighlightColor;
$contentStarterSeparatorBorder: 2px solid $contentStarterSeparatorColor;
$contentStarterSeparatorWidth: 74px;
$contentStarterTextFontSize: $baseFontSize;

// contactPage
$contactPageIconSize: 30px;
$contactPageIconFontSize: 15px;
$contactPageIconColor: $whiteColor;
$contactPageIconBackground: $grayColor;

// directOrderTeaser
$directOrderTeaserHeadlineFontSizeMobile: $fontSizeXYMedium;
$directOrderTeaserHeadlineFontSizeDesktop: $fontSizeXXMedium;
$directOrderTeaserHeadlineFontWeight: $fontWeightBold;
$directOrderTeaserHeadlineBorderBottom: $grayLighterColor 1px solid;
$directOrderTeaserHeadlineDisclaimerFontSize: $fontSizeXSmall;
$directOrderTeaserHeadlineDisclaimerLinkColor: $grayMediumColor;
$directOrderTeaserHeadlineNewsletterCheckFontSize: 16px;
$directOrderTeaserHeadlineNewsletterCheckSmallFontSize: 12px;
$directOrderTeaserHeadlineTextFontSize: 14px;
$directOrderTeaserHeadlineCheckboxLabelFontSize: $fontSizeSmall;
$directOrderTeaserHeadlineCheckboxBoxTop: 13px;
$directOrderTeaserHeadlineErrorFontSize: $fontSizeSmall;
$directOrderTeaserHeadlineLinkColor: $brandPrimaryColor;
$directOrderTeaserElementPaddingOuter: 0 5px;
$directOrderTeaserInputBackgroundColor: $inputBackgroundColor;

// productFeatures
$productFeaturesListItemMargin: 0 10px 10px 0;
$productFeaturesListLabelTextTransform: none;
$productFeaturesListLabelColor: $grayColor;
$productFeaturesListLabelFontWeight: normal;
$productFeaturesListBorderBottom: 1px solid $grayColor;
$productFeaturesListValueColor: $textBaseColor;
$productFeaturesListBorderTop: none;
$productFeaturesIconColor: $brandPrimaryColor;
$productFeaturesHeadlineBorderTop: 0 none;

// NewsletterPage
$newsletterPageSubHeadlineColor: $redDarkColor;
$newsletterPageEmailValueColor: $redDarkColor;
$newsletterPageTextSucessColor: $redDarkColor;
$newsletterPageCheckboxTop: 50%;
$newsletterPageCheckboxTransform: translateY(-50%);
$newsletterPageHeadlineDisplay: block;

// Newsletter
$newsletterInfoFontSize: 16px;
$newsletterLabelFontSize: 16px;
$newsletterDisclaimerFontSize: 14px;

// infoPopup
$infoPopupFontSize: 14px;
$infoPopupLineHeight: 1.2;
$infoPopupPadding: 8px 26px 8px 8px;
$infoPopupBackground: $whiteColor;
$infoPopupColor: $grayDarkColor;
$infoPopupInfoBackground: $grayLighterColor;
$infoPopupInfoBorder: 0 none;
$infoPopupInfoFontSize: 16px;
$infoPopupInfoSize: 16px;
$infoPopupTopMobile: 10%;

// imageSliderTeaser
$imageSliderTeaserTextColor: $grayColor;
$imageSliderTeaserTextFontSize: $fontSizeXSmall;

// table
$tableFontWeightBold: $fontWeightBold;
$tableLineHeight: 1.4;
$tableTextColor: $textDarkestColor;
$tableTextFontSize: $baseFontSize;
$tableLabelFontSize: 14px;
$tableBorderColor: $textDarkestColor;

// teaserGroupSlider
$teaserGroupSliderItemsContainerMarginTop: 15px;
$teaserGroupSliderItemsContainerMarginBottom: 15px;

// processTeaser
$processTeaserPadding: 20px 0;
$processTeaserWhitePadding: 20px;
$processTeaserBackground: transparent;
$processTeaserColor: $brandPrimaryColor;
$processTeaserWhiteBackground: $whiteColor;
$processTeaserWhiteColor: $textDarkestColor;
$processTeaserGrayBackground: $grayLighterColor;
$processTeaserButtonContainerBackground: $grayLighterColor;
$processTeaserFontSize: 12px;
$processTeaserFontSizeDesktop: 14px;
$processTeaserDividerColor: $brandPrimaryColor;
$processTeaserIconColor: $brandPrimaryColor;
$processTeaserIconWhiteColor: $textDarkestColor;
$processTeaserMaxWidth: 200px;

// categoryPage
$categoryPageHeadlineColor: $textBaseColor;

// contentIndex
$contentIndexHeadTopM: 60px;
$contentIndexHeadTopD: 77px;
$contentIndexHeadColor: $textDarkestColor;
$contentIndexTextColor: $textBaseColor;
$contentIndexCountryColor: $textLightColor;
$contentIndexArrowColor: $brandPrimaryColor;

// contentIndexHeader
$contentIndexHeaderBackgroundColor: $backgroundWhiteColor;
$contentIndexHeaderTextColor: $textDarkestColor;
$contentIndexHeaderDisabledColor: $grayLightColor;
$contentIndexHeaderPadding: 8px 0;

// readMore
$readMoreBtnFontWeight: $fontWeightBold;

// customerService
$customerServiceButtonBarMarginMobile: 0 0 15px;
$customerServiceButtonBarMarginDesktop: 0 0 30px;

// navigation
$navigationElementColor: $textBaseColor;
$navigationElementBorderBottom: 2px solid transparent;
$navigationElementHoverColor: $textBaseColor;
$navigationElementHoverBorderBottom: 2px solid $textHoverColor;
$navigationLinkFontSize: $baseFontSize;
$navigationLinkFontWeight: $fontWeightRegular;

$navigationFlyoutColor: $textHoverColor;
$navigationFlyoutHoverColor: $textHoverColor;
$navigationFlyoutAllColor: $textBaseColor;
$navigationFlyoutAllHoverColor: $textHoverColor;
$navigationFlyoutPadding: 35px 25px;
$navigationFlyoutListMargin: 15px 0;

// $navigationFlyoutTeaser
$navigationFlyoutTeaserBackground: $redDarkColor;
$navigationFlyoutTeaserTextColor: $whiteColor;
$navigationFlyoutTeaserButtonDecoration: underline;
$navigationFlyoutTeaserButtonTransform: inherit;

// headerMetaContent
$headerMetaContentColor: $grayMediumColor;
$headerMetaContentFontWeight: $fontWeightMedium;
$headerMetaContentTextDecoration: none;
$headerMetaContentFontSize: $fontSizeSmall;
$headerMetaContentHoverColor: inherit;
$headerMetaContentHoverLineColor: $textHoverColor;

// BurgerNavTree
$burgerNavTreeLinkColor: $textDarkestColor;
$burgerNavTreeLinkFontSize: $baseFontSize;
$burgerNavTreeLinkHighlightedColor: $brandPrimaryColor;
$burgerNavTreeLinkFontWeight: $fontWeightMedium;
$burgerNavTreeHeadlineColor: $brandPrimaryColor;
$burgerNavTreeHeadlineFontSize: $fontSizeXLarge;
$burgerNavTreeArrowRightColor: $brandPrimaryColor;
$burgerNavTreeIconColor: $textDarkestColor;

// productDescription
$productDescriptionImageDistance: 25px;

// footerSeo
$footerSeoColor: $textBaseColor;
$footerSeoFontSize: $baseFontSize;
$footerSeoBorderTop: 1px solid $textBaseColor;
$footerSeoImgContainerPaddingTop: 40px;
$footerSeoImgContainerPaddingTopChild: 20px;
$footerSeoPadding: 0;

// accountAddresses
$accountAddressesBoxPrimaryBorderColor: $brandPrimaryColor;
$accountAddressesListMinHeight: 40vh;

// wishlistEdit
$wishlistEditListNameArrowColor: $brandPrimaryColor;

// wishlistDialog
$wishlistDialogInputBackground: $backgroundGrayColor;
$wishlistDialogItemPadding: 8px 0;
$wishlistDialogItemPaddingMobile: 8px 0;

// productReviewHistogram
$productReviewHistogramLabelWidth: 90px;
$productReviewHistogramLabelFontWeight: $fontWeightSemiBold;
$productReviewHistogramBarBorder: 3px;
$productReviewHistogramBarBackground: $grayLightColor;
$productReviewHistogramBarHeight: 6px;
$productReviewHistogramBarFillBackground: $brandPrimaryColor;
$productReviewHistogramCountColor: $textBaseColor;
$productReviewHistogramCountWidth: 45px;
$productReviewHistogramFontSize: $baseFontSize;

// lightbox
$lightboxCloseBtnColor: $textBaseColor;
$lightBoxBorderRadius: 0;

// ContentPage
$contentPagePaddingMobile: 0;
$contentPagePaddingDesktop: 0;

// WineTasting
$wineTastingFacebookColor: rgb(76 120 241);

// CarouselSteps
$carouselStepsPrimaryColor: $brandSecondaryColor;
$carouselStepsContentBackground: transparent;
$carouselStepsLineColor: $grayLightColor;
$carouselStepsTextHoverColor: $whiteColor;
$carouselStepsBackgroundHoverColor: $brandSecondaryColor;
$carouselStepsTextColor: $textBaseColor;

// socialMessage
$socialMessageBackground: rgb(255 255 255 / .8);
$socialMessageNameColor: rgb(76 120 241);
$socialMessageTextColor: $textBaseColor;
$socialMessageLikeBackground: rgb(255 255 255 / 1);
$socialMessageLikeShadow: 0 1px 2px 1px rgb(0 0 0 / .2);
$socialMessageLikeColor: $grayColor;
$socialMessageHeartBackground: $redColor;
$socialMessageHeartColor: $whiteColor;

// FavoriteWineTeaser
$favoriteWineBackground: $grayLighterColor;

// WishlistShare
$wishlistShareTextPaddingBottom: 0;

// inspirationList
$inspirationListMarginTop: -24px;
$inspirationListIntroTextAlign: center;
$inspirationListHeadlineTextColor: $textDarkestColor;
$inspirationListTabBoxShadow: 0 3px 7px 0 rgb(0 0 0 / .3);
$inspirationListTabBorderRadius: 20px 20px 0 0;
$inspirationListTabBackgroundColor: $whiteColor;
$inspirationListTabTextColor: $grayColor;
$inspirationListTabTextHighlightColor: $textDarkestColor;
$inspirationListTabContentBackgroundColor: transparent;
$inspirationListTabContentBackgroundShadow: 0 -5px 2px -4px rgba(0 0 0 / .2), 0 1px 7px -1px rgba(0 0 0 / .2);
$inspirationListTabContentGradientColor: linear-gradient(180deg, rgb(255 255 255 / 1) 0%, rgb(255 255 255 / 1) 68%, rgb(255 255 255 / 0) 100%);

// accountRecurringOrderItem
$accountRecurringOrderItemBorder: $borderDefaultColor 1px solid;
$accountRecurringOrderItemBoxPadding: 16px;
$accountRecurringOrderItemBoxBackground: $whiteColor;
$accountRecurringOrderItemBoxItemsBackground: $whiteColor;
$accountRecurringOrderItemAfterIcon: arrowRight;
$accountRecurringOrderItemAfterIconFontSize: 16px;
$accountRecurringOrderItemAfterIconTransform: translateY(-1px) rotate(90deg);
$accountRecurringOrderItemAfterIconTransformOpen: translateY(7px) rotate(90deg) scale(-1);
$accountRecurringOrderItemPaddingItems: 6px;
$accountRecurringOrderItemSubmitOrder: 1;

// accountRecurringOrderShippingDate
$accountRecurringOrderShippingDateBackground: $bodyBackgroundColor;

// similarWinesTeaser
$similarWinesTeaserHeadlineColor: $textDarkestColor;

// onePageCheckoutForm
$onePageCheckoutFormBackground: $backgroundWhiteColor;
$onePageCheckoutFormInputBackground: $inputBackgroundColor;
$onePageCheckoutFormCheckboxLabelFontSize: 16px;

// similarWinesSuggestTeaser
$similarWinesSuggestTeaserTextColor: $textDarkestColor;
$similarWinesSuggestTeaserBubbleColor: $grayColor;
$similarWinesSuggestTeaserHeadlineSize: 52px;
$similarWinesSuggestTeaserHeadlineWeight: $fontWeightBold;
$similarWinesSuggestTeaserHeadlineFamily: $fontHeadlineFamily;
$similarWinesSuggestTeaserHeadlineSmallSize: 18px;
$similarWinesSuggestTeaserHeadlineSmallWeight: $fontWeightRegular;
$similarWinesSuggestTeaserHeadlineSmallFamily: $fontSecondaryFamily;
$similarWinesSuggestTeaserDescriptionSize: 18px;
$similarWinesSuggestTeaserDescriptionFamily: $fontSecondaryFamily;
$similarWinesSuggestTeaserSearchIcon: 'search';
$similarWinesSuggestTeaserItemBoxShadowActive: 0 0 2px 1px $grayLightColor inset;
$similarWinesSuggestTeaserButtonClearButtonTop: 16px;

// native App
$nativeAppBottomNavHeight: 60px;
$nativeAppHeaderHeight: 60px;
$nativeAppFilterListPadding: 54px 0 105px;

// referAFriendReward
$referAFriendRewardServiceIconSize: 70px;
$referAFriendRewardServiceIconColor: $brandSecondaryColor;
$referAFriendRewardServiceColor: $textDarkestColor;
$referAFriendRewardServiceColorFontSize: $fontSizeFont;
$referAFriendRewardTextHeaderSize: $fontSizeXMedium;
$referAFriendRewardTextHeaderBackground: $brandSecondaryColor;
$referAFriendRewardTextHeaderColor: $textDarkestColor;
$referAFriendRewardTextContentBackground: $whiteColor;
$referAFriendRewardTextContentColor: $textDarkestColor;
$referAFriendRewardDisclaimerColor: $textDarkestColor;
$referAFriendRewardDisclaimerMargin: 32px auto 0;
$referAFriendRewardAddressBoxSuccessPadding: 0;
$referAFriendRewardAddressBoxButtonMargin: 0 10px 20px;
$referAFriendRewardProductsMarginTop: 24px;
$referAFriendRewardProductBackgroundColor: #efd77d;
$referAFriendRewardProductBackground: radial-gradient(circle at top, #f3e8ba, #f3e8ba00 80%), linear-gradient(to top, #c3bba7 10%, #efd77d 40%);

// accountReferAFriendReward
$accountReferAFriendRewardListBackground: $whiteColor;
$accountReferAFriendRewardBorderBottom: 1px solid $textDarkestColor;
$accountReferAFriendRewardBarBackground: $grayLightColor;
$accountReferAFriendRewardBarSuccessBackground: $goldColor;

// localisationLayer
$localisationLayerLogoWidth: 114px;
$localisationLayerButtonBorder: 2px solid $redDarkColor;
$localisationLayerButtonBackground: $whiteColor;
$localisationLayerButtonText: $redDarkColor;
$localisationLayerButtonHoverBackground: $redDarkColor;
$localisationLayerButtonHoverText: $whiteColor;

// recipeTeaser
$recipeTeaserTextAlign: left;
$recipeTeaserColor: inherit;
$recipeTeaserBackgroundColor: #e7e0c3;
$recipeTeaserTextPadding: 25px;
$recipeTeaserFontSize: 18px;
$recipeTeaserHeadlineFontSize: $fontSizeXXXLarge;
$recipeTeaserHeadlineFontWeight: $fontWeightBold;
$recipeTeaserDrinkNameFontSize: $fontSizeXXXLarge;
$recipeTeaserDrinkNameFontFamily: $fontSecondaryFamily;
$recipeTeaserDrinkNameFontWeight: $fontWeightRegular;
$recipeTeaserDrinkNameMobileTextAlign: center;
$recipeTeaserDrinkNameDesktopTextAlign: left;
$recipeTeaserIngredientsTitleFontSize: inherit;
$recipeTeaserExtraInfoItemTextAlign: left;
$recipeTeaserExtraInfoItemLineHeight: normal;
$recipeTeaserBottomButtonWidth: 100%;

// billingDownloadButton
$billingDownloadButtonIconSize: 38px;
$billingDownloadButtonColor: $textBaseColor;
$billingDownloadButtonLoaderColor: $textBaseColor;
$billingDownloadButtonErrorColor: $errorColor;
$billingDownloadButtonSuccessColor: $textBaseColor;

// registerForm
$registerFormSubHeadlineFontSize: 20px;
$registerFormSubHeadlineMargin: 8px 0 20px;
$registerFormAdditionToggleMargin: 15px 0 0;

// registerAddressForm
$registerAddressFormOptionAreaBackground: $whiteColor;

// availabilityReminder
$availabilityReminderFontSize: $fontSizeSmall;

// generalMessageBorderRadius
$generalMessageBorderRadius: 0;

// sidePanel
$sidePanelBackground: #f1ede7;
$sidePanelCheckoutBackground: $whiteColor;
$sidePanelCheckoutFormBackground: #f1ede7;
$sidePanelMaxWidth: 500px;
$sidePanelCartMaxWidth: 500px;
$sidePanelCheckoutMaxWidth: 500px;
$sidePanelCloseIconName: 'delete';
$sidePanelCloseIconFontSize: 15px;
$sidePanelCloseIconRight: 16px;
$sidePanelCloseIconTop: 12px;
$sidePanelCloseBackground: transparent;
$sidePanelContainerTop: 26px;

//slotMachine
$slotMachineTeaserBackgroundLeft: #ddd;
$slotMachineTeaserBackgroundRight: #ffe9e6;
$slotMachineBtnBackgroundColor: $whiteColor;
$slotMachineVoucherTextColor: #fb5b4f;

//SlotMachineColumn
$slotMachineColumnBorderColor: #ecb748;
$slotMachineColumnItemBorderColor: #c98b25;

// BasketCrossSelling
$basketCrossSellingBorderRadius: 4px;
$basketCrossSellingBorder: 3px solid $brandSecondaryColor;
$basketCrossSellingBg: $grayLighterColor;

// Form
$formElementSpacing: 8px;

// benefitLine
$benefitLineBackgroundColor: $brandPrimaryColor;
$benefitLineTextColor: $brandPrimaryColor;
$benefitLineFontSizeTablet: 12px;
$benefitLineFontSizeDesktop: 18px;
$benefitLineFontWeight: $fontWeightRegular;
$benefitLineLineHeight: 1.1;
$benefitLinePadding: 4px 0;
$benefitLineMaxWidth: 1276px;
$benefitLineItemGapTablet: 8px;
$benefitLineItemGapDesktop: 8px;
$benefitLineItemIconSizeMobile: 24px;
$benefitLineItemIconSizeDesktop: 30px;
